import { User } from '../core/auth/user';
import { UserState } from './userState';

export function getUserStateLabel(user: Partial<User>): string {
    if (!user || !user.state) {
        return 'Unbekannt';
    }

    switch (user.state) {
        case 'ACTIVE':
            return 'Aktiv';
        case 'INACTIVE':
            return 'Deaktiviert';
        case 'INVITED':
            return 'Eingeladen';
        default:
            return 'Unbekannt';
    }
}

export function getUserStateDescription(state: UserState) {
    switch (state) {
        case UserState.ACTIVE:
            return 'Das Konto des Mitarbeiters ist aktiv. Der Mitarbeiter hat Zugriff auf sein Konto und kann die Zeiterfassung nutzen.';
        case UserState.INVITED:
            return 'Der Mitarbeiter wurde eingeladen, hat das Konto jedoch noch nicht aktiviert.';
        case UserState.INACTIVE:
            return 'Das Konto des Mitarbeiters wurde deaktiviert. Dadurch hat der Mitarbeiter keinen Zugriff mehr auf sein Konto. Auch die Zeiterfassung ist nicht mehr möglich. Die Notizfunktion steht Ihnen jedoch weiterhin zur Verfügung.';
        default:
            return '';
    }
}
