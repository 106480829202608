import CircleIcon from '@mui/icons-material/Circle';
import { Chip, Tooltip } from '@mui/material';
import React from 'react';
import { Colors } from '../../core/theme/Colors';
import { NfcTag } from '../nfcTag';

export const NfcTagStatus = ({ nfcTag }: { nfcTag: Partial<NfcTag> }) => {
    const isAssigned: boolean = !!nfcTag.user;
    const isFree: boolean = !isAssigned;
    const userIsInvited: boolean = nfcTag.user?.state === 'INVITED';
    const canBeReassigned = isAssigned && nfcTag.user?.state === 'INACTIVE';

    const label = isFree
        ? 'Frei'
        : canBeReassigned
        ? 'Frei*'
        : userIsInvited
        ? 'Aktiv*'
        : 'Aktiv';

    const bgColor = isFree
        ? Colors.GreenLightest
        : canBeReassigned
        ? Colors.OrangeLightest
        : Colors.PrimaryLightest;

    const textColor = isFree
        ? Colors.GreenDarkest
        : canBeReassigned
        ? Colors.OrangeDarkest
        : Colors.PrimaryDarker;

    const borderColor = isFree
        ? Colors.Green
        : canBeReassigned
        ? Colors.Orange
        : Colors.Primary;

    const tooltipTitle = isFree
        ? 'ID-Karte ist keinem Mitarbeiter zugewiesen'
        : canBeReassigned
        ? 'ID-Karte ist einem deaktiviertem Mitarbeiter zugewiesen und kann neu zugewiesen werden'
        : userIsInvited
        ? 'ID-Karte ist einem eingeladenen Mitarbeiter zugewiesen und wird automatisch aktiv, sobald der Mitarbeiter sein Konto aktiviert hat'
        : 'ID-Karte ist einem aktiven Mitarbeiter zugewiesen';

    return (
        <Tooltip title={tooltipTitle}>
            <Chip
                icon={
                    <CircleIcon
                        style={{
                            width: 9,
                            height: 9,
                            paddingRight: '1px',
                            fill: borderColor
                        }}
                    />
                }
                style={{
                    fontSize: 11,
                    backgroundColor: bgColor,
                    textTransform: 'uppercase',
                    fontWeight: 700,
                    color: textColor,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: borderColor,
                    letterSpacing: 0.2
                }}
                size={'small'}
                label={label}
            />
        </Tooltip>
    );
};
