import { OrganizationDto } from '../core/auth/organization.dto';

export function orgHasFeature(
    organization: Partial<OrganizationDto> | undefined,
    feature: string
): boolean {
    if (!organization) {
        return false;
    }

    const activeFeatures = organization.activeFeatures_BETA;
    if (!activeFeatures) {
        return false;
    }

    return activeFeatures.indexOf(feature) >= 0;
}
