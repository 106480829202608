import styled from '@emotion/styled';
import BadgeIcon from '@mui/icons-material/Badge';
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { Colors } from '../../core/theme/Colors';
import { OnOrgFeature } from './OnOrgFeature';

const StyledHeaderCell = styled(TableCell)`
    white-space: nowrap;

    svg {
        fill: ${Colors.Secondary};
        width: 16px;
        height: 16px;
        margin-right: 0.15em;
        vertical-align: middle;
        transform: translateY(-0.05em);
    }
`;

export const OrganizationMembersTableWrapper = ({
    children
}: PropsWithChildren<any>) => (
    <Table>
        <TableHead>
            <TableRow>
                <StyledHeaderCell
                    component="th"
                    style={{ width: 'auto', maxWidth: '50%' }}
                    colSpan={2}>
                    <PersonIcon /> Mitarbeiter
                </StyledHeaderCell>
                <StyledHeaderCell component="th" width={'40%'}>
                    <KeyIcon /> Berechtigung
                </StyledHeaderCell>
                {/*<OnOrgFeature feature={'id_tags'}>*/}
                {/*    <StyledHeaderCell component="th">*/}
                {/*        <BadgeIcon /> ID-Karte*/}
                {/*    </StyledHeaderCell>*/}
                {/*</OnOrgFeature>*/}
                <TableCell component="th" sx={{ width: '150px' }} />
            </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
    </Table>
);
