import React, { PropsWithChildren } from 'react';
import { User } from '../core/auth/user';
import { Colors } from '../core/theme/Colors';
import { getUserAvatarBgColor } from './index';
import PersonIcon from '@mui/icons-material/Person';

const UserAvatarCt = ({
    bgColor,
    txtColor,
    small,
    children
}: PropsWithChildren<{
    bgColor?: string;
    txtColor?: string;
    small?: boolean;
}>) => {
    return (
        <div
            style={{
                width: small ? 28 : 32,
                height: small ? 28 : 32,
                borderRadius: '50%',
                backgroundColor: bgColor || Colors.SecondaryLightest,
                display: 'flex',
                flexShrink: 0,
                justifyContent: 'center',
                alignItems: 'center',
                color: txtColor || Colors.Text,
                fontWeight: small ? 600 : 700,
                letterSpacing: small ? -0.05 : -0.1,
                fontSize: 13
            }}>
            {children}
        </div>
    );
};

export const UserAvatar = ({
    user,
    small,
    greyscale
}: {
    user?: User | null;
    small?: boolean;
    greyscale?: boolean;
}) => {
    if (!user) {
        return (
            <UserAvatarCt small={small}>
                <PersonIcon
                    style={{
                        width: small ? 14 : 22,
                        height: small ? 14 : 22,
                        fill: Colors.Secondary
                    }}
                />
            </UserAvatarCt>
        );
    }

    let initials = '';
    if (user.firstName) {
        initials += user.firstName[0];
    }
    if (user.lastName) {
        initials += user.lastName[0];
    }
    if (!initials && user.email) {
        initials = user.email[0];
    }
    initials = initials.toUpperCase();

    return (
        <UserAvatarCt
            bgColor={
                greyscale
                    ? Colors.SecondaryLightest
                    : getUserAvatarBgColor(user)
            }
            txtColor={greyscale ? Colors.Text : undefined}
            small={small}>
            <span style={{ marginTop: small ? undefined : '-0.05em' }}>
                {initials}
            </span>
        </UserAvatarCt>
    );
};
