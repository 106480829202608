import styled from '@emotion/styled';
import CircleIcon from '@mui/icons-material/Circle';
import LabelIcon from '@mui/icons-material/Label';
import PersonIcon from '@mui/icons-material/Person';
import TagIcon from '@mui/icons-material/Tag';
import TodayIcon from '@mui/icons-material/Today';
import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { Colors } from '../../core/theme/Colors';

const StyledHeaderCell = styled(TableCell)`
    svg {
        fill: ${Colors.Secondary};
        width: 16px;
        height: 16px;
        margin-right: 0.15em;
        vertical-align: middle;
        transform: translateY(-0.05em);
    }
`;

const TableCt = styled.div`
    position: relative;
    z-index: 1;
`;

const StyledActivityIndicator = styled(CircularProgress)`
    position: absolute;
    top: 13px;
    right: 16px;
`;

export const NfcTagsTableWrapper = ({
    loading,
    totalCount,
    filteredCount,
    children
}: PropsWithChildren<{
    loading?: boolean;
    totalCount: string | number;
    filteredCount?: number;
}>) => {
    const isFiltered =
        filteredCount !== undefined && filteredCount < totalCount;
    return (
        <TableCt>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledHeaderCell
                            style={{ width: '2em', paddingRight: 0 }}
                            component="th">
                            <TagIcon />
                        </StyledHeaderCell>
                        <StyledHeaderCell
                            component="th"
                            style={{
                                width: 140,
                                textAlign: 'center'
                            }}>
                            <CircleIcon
                                style={{ width: 11, height: 11, marginTop: 1 }}
                            />{' '}
                            Status
                        </StyledHeaderCell>
                        <StyledHeaderCell component="th">
                            <LabelIcon /> Beschriftung
                        </StyledHeaderCell>
                        <StyledHeaderCell component="th">
                            <PersonIcon /> Mitarbeiter
                        </StyledHeaderCell>
                        <StyledHeaderCell
                            component="th"
                            style={{ paddingLeft: 0 }}>
                            <Tooltip title={'Zugewiesen am'}>
                                <>
                                    <TodayIcon /> Zugewiesen
                                </>
                            </Tooltip>
                        </StyledHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>{children}</TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell
                            colSpan={4}
                            style={{
                                color: Colors.TextLighter,
                                fontSize: 13,
                                paddingTop: 12,
                                paddingBottom: 12
                            }}>
                            <strong>
                                {isFiltered ? filteredCount : totalCount}
                            </strong>
                            {isFiltered ? '/' + totalCount : null} ID-Karten
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
            {loading && (
                <StyledActivityIndicator
                    size={16}
                    color={'secondary'}
                    title={'Aktualisiere NFC-Tags'}
                />
            )}
        </TableCt>
    );
};
