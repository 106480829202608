import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import BadgeIcon from '@mui/icons-material/Badge';
import { Button, Popover } from '@mui/material';
import React, { useRef, useState } from 'react';
import { NotAvailable } from '../../common/components/NotAvailable';
import { User } from '../../core/auth/user';
import { shakeElement } from '../../core/theme/utils';
import { executeSafe } from '../../utils';
import { AddUserNfcTagAssignmentPopoverForm } from './AddUserNfcTagAssignmentPopoverForm';

const POPOVER_ID = 'user-add-nfc-tag-asignment-inline-popover';

const Ct = styled.div``;

type Props = {
    user: User;
    hasSome: boolean;
    onAdded?: () => void;
};

export const EmployeeAddNfcTag = ({ user, onAdded, hasSome }: Props) => {
    const [anchorEl, setAnchorEl] =
        React.useState<HTMLButtonElement | null>(null);

    const ctRef = useRef<any>();

    const [isDirty, setDirty] = useState(false);

    const onAssignTag = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(ctRef.current);
    };

    const shakeEditPopover = () => {
        const element = document.getElementById(POPOVER_ID);
        shakeElement(element);
    };

    const closeInlineForm = () => {
        setAnchorEl(null);
    };

    const onAdd = () => {
        closeInlineForm();
        executeSafe(onAdded);
    };

    const handleClose = (ignoreDirty: boolean) => {
        if (isDirty && !ignoreDirty) {
            shakeEditPopover();
            return;
        }
        closeInlineForm();
    };

    const popoverIsOpen = Boolean(anchorEl);
    const popoverId = popoverIsOpen ? POPOVER_ID : undefined;

    return (
        <Ct ref={ctRef}>
            {!hasSome && (
                <div>
                    <NotAvailable
                        label={
                            'Mitarbeiter hat bisher keine zugewiesene ID-Karte'
                        }
                        showTooltip={false}
                    />
                </div>
            )}
            <Button
                onClick={onAssignTag}
                variant="text"
                color="primary"
                startIcon={<AddIcon style={{ marginRight: -4 }} />}
                style={{ marginLeft: -20, marginTop: 8 }}>
                ID-Karte zuweisen
            </Button>
            <Popover
                id={popoverId}
                open={popoverIsOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                onClose={() => handleClose(false)}>
                <AddUserNfcTagAssignmentPopoverForm
                    user={user}
                    onAdd={onAdd}
                    onAbort={() => handleClose(true)}
                    onDirty={isDirty => setDirty(isDirty)}
                />
            </Popover>
        </Ct>
    );
};
