import styled from '@emotion/styled';
import BadgeIcon from '@mui/icons-material/Badge';
import React from 'react';
import { ProtectedPage } from '../core/auth/components/ProtectedPage';
import { Permission } from '../core/auth/permission';
import { PageBody } from '../core/components/page/PageBody';
import { PageHeader } from '../core/components/page/PageHeader';
import { PageHeadline } from '../core/components/page/PageHeadline';
import { PageSubHeadline } from '../core/components/page/PageSubHeadline';
import { BetaBadge } from '../core/components/TeaserBetaBadge';
import { PageHeadlineContainer } from '../employee/EmployeePageHeadlineContainer';
import { NfcTagList } from './components/NfcTagList';

const StyledBadgeIcon = styled(BadgeIcon)`
    margin-top: -0.05em;
`;

export const NfcTagsPage = () => {
    return (
        <ProtectedPage
            title="ID-Karten"
            permissions={Permission.Manage_Users}
            feature={'id_tags'}>
            <PageHeader>
                <PageHeadlineContainer>
                    <PageHeadline>
                        <StyledBadgeIcon aria-hidden /> ID-Karten
                    </PageHeadline>
                    <BetaBadge size={'large'} />
                </PageHeadlineContainer>
                <PageSubHeadline>
                    ID-Karten zur Nutzung des Dajeh-Terminals verwalten
                </PageSubHeadline>
            </PageHeader>

            <PageBody>
                <NfcTagList />
            </PageBody>
        </ProtectedPage>
    );
};
