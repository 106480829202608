import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { orgHasFeature } from '../../../organization/utils';
import { GlobalDrawerFormContextProvider } from '../../components/form/GlobalDrawerFormContextProvider';
import { useAuthenticatedContext } from '../../hooks/useAuthenticatedContext';
import { AppNavigationBar } from '../../navigation/AppNavigationBar';
import { Paths } from '../../navigation/paths';
import { userHasSomePermissions } from '../../../user';
import { InactiveFeatureErrorPage } from './InactiveFeatureErrorPage';
import { InsufficientPrivilegesPage } from './InsufficientPrivilegesPage';
import { BasePage } from '../../components/page/BasePage';

const AppLayout = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export type ProtectedPagePropType = PropsWithChildren<{
    title?: string;
    permissions?: string | string[];
    feature?: string;
    noWrapper?: boolean;
}>;

const ProtectedPageInner = ({
    title,
    children,
    permissions,
    feature
}: ProtectedPagePropType) => {
    const { user } = useAuthenticatedContext();
    const hasSomePermissions = userHasSomePermissions(user, permissions);

    if (!hasSomePermissions) {
        return <InsufficientPrivilegesPage title={title} />;
    }

    if (feature && !orgHasFeature(user?.organization, feature)) {
        return <InactiveFeatureErrorPage title={title} />;
    }

    return (
        <BasePage title={title}>
            <AppLayout>{children}</AppLayout>
        </BasePage>
    );
};

export const ProtectedPage = ({
    children,
    noWrapper,
    ...otherProps
}: ProtectedPagePropType) => {
    const { authenticated } = useAuthenticatedContext();
    const location = useLocation();

    if (!authenticated) {
        return (
            <Navigate
                to={Paths.Login}
                replace
                state={{ redirectPathAfterSignIn: location.pathname }}
            />
        );
    }

    if (noWrapper) {
        return <>{children}</>;
    }

    return (
        <ProtectedPageInner {...otherProps}>
            <GlobalDrawerFormContextProvider>
                <AppNavigationBar />
                {children}
            </GlobalDrawerFormContextProvider>
        </ProtectedPageInner>
    );
};
