import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GlobalAlert } from '../../common/components/GlobalAlert';
import { getRoleLabel } from '../../core/auth';
import { ProtectedPage } from '../../core/auth/components/ProtectedPage';
import { useUser } from '../../core/auth/useUser';
import { PageBody } from '../../core/components/page/PageBody';
import { PageHeader } from '../../core/components/page/PageHeader';
import { PageHeadline } from '../../core/components/page/PageHeadline';
import { PageSubHeadline } from '../../core/components/page/PageSubHeadline';
import { useDocumentTitle } from '../../core/hooks/useDocumentTitle';
import { getUserName } from '../index';
import { UserState } from '../userState';
import { UserSettingsPageContent } from './UserSettingsPageContent';

export const UserSettingsPage = () => {
    const [pageTitle, setPageTitle] = useState('');
    const user = useUser();

    useDocumentTitle(pageTitle);

    useEffect(() => {
        setPageTitle(`${getUserName(user)} - Profil`);
    }, [user]);

    const isInactive = user?.state === UserState.INACTIVE;

    return (
        <ProtectedPage>
            <PageHeader>
                <PageHeadline>{getUserName(user)}</PageHeadline>
                <PageSubHeadline>
                    {user?.email}
                    {' • '}
                    <Tooltip title={'Berechtigung'}>
                        <span>{getRoleLabel(user?.role)}</span>
                    </Tooltip>
                </PageSubHeadline>
            </PageHeader>

            <PageBody>
                {isInactive && (
                    <GlobalAlert
                        severity="info"
                        sx={{ marginBottom: { xs: '16px', md: '24px' } }}>
                        Ihr Konto wurde deaktiviert. Wenn Sie Ihr Konto wieder
                        aktivieren möchten, kontaktieren Sie bitte den
                        Dajeh-Support: hilfe@dajeh.de
                    </GlobalAlert>
                )}

                <UserSettingsPageContent />
            </PageBody>
        </ProtectedPage>
    );
};
