import { gql } from '@apollo/client';

export const QUERY_USER_INVITATION_PUBLIC_DATA = gql`
    query GetUserInvitationPublicDataBeta($token: String!) {
        publicUserInvitationBeta(token: $token) {
            email
            firstName
            lastName
            organizationName
            role
        }
    }
`;

export const QUERY_ME = gql`
    query FetchAuthUser {
        me {
            id
            email
            firstName
            lastName
            permissions
            role
            isOwner
            unreadNotificationsCount
            organization {
                id
                name
                activeSubscription_BETA {
                    validUntil
                    isTrial
                }
                activeFeatures_BETA
            }
            activeTimeTracking {
                id
                description
                startDate
            }
        }
    }
`;

export const QUERY_USER_DETAILS = gql`
    query FetchUser($id: ID!) {
        node(id: $id) {
            ... on User {
                id
                email
                firstName
                lastName
                name
                role
                state
                internalNote
                isOwner
                activeTimeTracking {
                    id
                    description
                    startDate
                }
                pendingInvitation {
                    id
                    validUntil
                    createdAt
                    createdBy {
                        id
                        name
                    }
                    updatedAt
                    updatedBy {
                        id
                        name
                    }
                }
                nfcTags {
                    id
                    label
                    assignedAt
                }
            }
        }
    }
`;

export const QUERY_USER_ONBOARDING_DOCUMENT_DATA = gql`
    query UserOnboardingDocumentData($id: ID!) {
        node(id: $id) {
            ... on User {
                id
                email
                firstName
                lastName
                role
                organization {
                    id
                    name
                }
                pendingInvitation {
                    id
                    validUntil
                    acceptUrl
                    acceptUrlQrCode
                    createdAt
                    createdBy {
                        id
                        name
                    }
                    updatedAt
                    updatedBy {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const MUTATION_UPDATE_INTERNAL_NOTE = gql`
    mutation UpdateUser($id: ID!, $input: UpdateUserInput!) {
        updateUser(id: $id, input: $input) {
            user {
                id
                internalNote
            }
        }
    }
`;

export const MUTATION_UPDATE_PASSWORD = gql`
    mutation UpdatePassword($input: UpdatePasswordInput!) {
        updatePassword(input: $input) {
            user {
                id
                updatedAt
            }
        }
    }
`;

export const QUERY_PASSWORD_STRENGTH = gql`
    query PasswordStrength($password: String!) {
        passwordStrength(password: $password) {
            score
            feedback {
                warning
                suggestions
            }
        }
    }
`;

export const MUTATION_DEACTIVATE_USER = gql`
    mutation DeactivateUser($id: ID!) {
        deactivateUser(id: $id) {
            user {
                id
                firstName
                lastName
                state
            }
        }
    }
`;

export const MUTATION_ACTIVATE_USER = gql`
    mutation ActivateUser($id: ID!) {
        activateUser(id: $id) {
            user {
                id
                firstName
                lastName
                state
            }
        }
    }
`;

export const SIGN_OUT = gql`
    mutation SignOut {
        signOut
    }
`;

export const MUTATION_CREATE_USER = gql`
    mutation createUser($input: CreateUserInput!) {
        createUser(input: $input) {
            user {
                id
                email
                firstName
                lastName
            }
        }
    }
`;

export const MUTATION_RENEW_USER_INVITATION = gql`
    mutation RenewUserInvitation($id: ID!) {
        renewUserInvitation(id: $id) {
            userInvitation {
                id
                validUntil
            }
        }
    }
`;

export const MUTATION_RESEND_USER_INVITATION = gql`
    mutation ResendUserInvitation($id: ID!) {
        resendUserInvitation(id: $id)
    }
`;

export const MUTATION_ACCEPT_INVITATION = gql`
    mutation AcceptUserInvitation(
        $token: String!
        $input: AcceptInvitationInput!
    ) {
        acceptUserInvitation(token: $token, input: $input)
    }
`;

export const MUTATION_UPDATE_USER = gql`
    mutation updateUser($id: ID!, $input: UpdateUserInput!) {
        updateUser(id: $id, input: $input) {
            user {
                id
                email
                firstName
                lastName
                role
            }
        }
    }
`;

export const MUTATION_DELETE_USER = gql`
    mutation deleteUser($id: ID!) {
        deleteUser(id: $id) {
            user {
                id
                email
                firstName
                lastName
                deletedAt
            }
        }
    }
`;
