import styled from '@emotion/styled';
import { Chip, Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';
import { User } from '../core/auth/user';
import { Colors } from '../core/theme/Colors';
import { getUserStateDescription, getUserStateLabel } from './labels';
import { UserState } from './userState';

function getUserStateChipBackgroundColor(state: UserState): string {
    if (state === 'INACTIVE') {
        return Colors.SecondaryLightest;
    }

    if (state === 'INVITED') {
        return Colors.OrangeLightest;
    }

    return Colors.GreenLightest;
}

const StyledChip = styled(Chip)`
    font-size: 11px;
    line-height: 15px;
    font-weight: 600;
    border-width: 1px;
    border-style: solid;
    letter-spacing: 0.4px;
    text-transform: uppercase;
`;

function getUserStateChipBorderColor(state: UserState): string {
    if (state === 'INACTIVE') {
        return Colors.SecondaryLight;
    }

    if (state === 'INVITED') {
        return Colors.OrangeLight;
    }

    return Colors.GreenLight;
}

function getUserStateChipTextColor(state: UserState): string {
    if (state === 'INACTIVE') {
        return Colors.SecondaryDark;
    }

    if (state === 'INVITED') {
        return Colors.OrangeDark;
    }

    return Colors.GreenDark;
}

export const UserStateChip = ({
    user,
    showActive
}: {
    user: User;
    showActive?: boolean;
}): ReactElement | null => {
    if (!user) {
        return null;
    }

    const state = user.state;
    if (!state) {
        return null;
    }

    if (state === 'ACTIVE' && !showActive) {
        return null;
    }

    const label = getUserStateLabel(user);
    const desc = getUserStateDescription(state);
    const backgroundColor = getUserStateChipBackgroundColor(state);
    const borderColor = getUserStateChipBorderColor(state);
    const textColor = getUserStateChipTextColor(state);

    return (
        <Tooltip title={desc}>
            <StyledChip
                label={label}
                size="small"
                sx={{
                    backgroundColor,
                    borderColor,
                    color: textColor
                }}
            />
        </Tooltip>
    );
};
