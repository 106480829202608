import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';
import { PropsWithChildren } from 'react';

type TeaserBetaBadgeProps = {
    size?: 'small' | 'medium' | 'large';
    className?: string;
};

const Badge = styled.span<{ size: 'small' | 'medium' | 'large' }>`
    display: inline-block;
    border-radius: 15px;
    padding: ${props =>
        props.size === 'small'
            ? '1px 6px'
            : props.size === 'large'
            ? '4px 12px 2px'
            : '3px 8px'};
    background: #fa5aa3;
    font-weight: 600;
    font-size: ${props =>
        props.size === 'small'
            ? '11px'
            : props.size === 'large'
            ? '14px'
            : '12px'};
    line-height: ${props =>
        props.size === 'small'
            ? '15px'
            : props.size === 'large'
            ? '15px'
            : '15px'};
    color: #ffffff;
    text-transform: uppercase;
`;

const BadgeCt = ({
    size,
    className,
    children
}: PropsWithChildren<TeaserBetaBadgeProps>) => {
    const _size = size || 'medium';
    return (
        <Badge size={_size} className={className}>
            {children}
        </Badge>
    );
};

export const TeaserBadge = (props: TeaserBetaBadgeProps) => (
    <BadgeCt {...props}>
        <Tooltip title={'Bald verfügbar'}>
            <span>Bald</span>
        </Tooltip>
    </BadgeCt>
);

export const BetaBadge = (props: TeaserBetaBadgeProps) => (
    <BadgeCt {...props}>
        <Tooltip title={'Beta-Version - Feedback willkommen!'}>
            <span>Beta</span>
        </Tooltip>
    </BadgeCt>
);
