import { useLazyQuery, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { SecondaryButton } from '../../common/components/button/SecondaryButton';
import { getApiErrorCode } from '../../core/api/error';
import { User } from '../../core/auth/user';
import { Colors } from '../../core/theme/Colors';
import { getUserName } from '../../user';
import { UserStateChip } from '../../user/UserStateChip';
import { executeSafe } from '../../utils';
import { NfcTag } from '../nfcTag';
import {
    MUTATION_ASSIGN_NFC_TAG,
    QUERY_NFC_TAGS_FOR_USER_ASSIGNMENT,
    QUERY_USERS_FOR_NFC_TAG_ASSIGNMENT
} from '../queries';

const EditNfcTagUserAssignmentPopoverCt = styled.div`
    padding: 16px;
    width: 400px;
`;

const UserName = styled.span`
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 0;
`;

const NfcTagLabel = styled.span`
    display: block;
    padding: 2px 6px 1px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 11px;
    font-family: monospace;
    font-weight: 600;
    color: ${Colors.SecondaryDarker};
    background: white;
    border: 1px solid ${Colors.SecondaryLightest};
    border-radius: 2px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
    text-overflow: ellipsis;
`;

type UserOption = {
    id: string;
    user: User;
    nfcTags: NfcTag[];
    state: '0-currently-assigned' | '1-unassigned' | '2-assigned';
};

function getCurrentUserOption(nfcTag?: NfcTag): UserOption | null {
    if (!nfcTag || !nfcTag.user) {
        return null;
    }

    return {
        id: nfcTag.user.id,
        user: nfcTag.user,
        nfcTags: [nfcTag],
        state: '0-currently-assigned'
    };
}

export const EditNfcTagUserAssignmentPopoverForm = ({
    nfcTag,
    onSave,
    onAbort,
    onDirty
}: {
    nfcTag: NfcTag;
    onSave: () => void;
    onAbort: () => void;
    onDirty: (dirty: boolean) => void;
}) => {
    const [working, setWorking] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [fetchUsers, { loading, data }] = useLazyQuery(
        QUERY_USERS_FOR_NFC_TAG_ASSIGNMENT,
        {
            fetchPolicy: 'cache-and-network'
        }
    );
    const [chosenUserId, setChosenUserId] = useState<string | null>(
        nfcTag.user?.id || null
    );

    const [mutationAssignNfcTag] = useMutation(MUTATION_ASSIGN_NFC_TAG);

    const initialUserOption = getCurrentUserOption(nfcTag);
    const initialUserOptions = initialUserOption ? [initialUserOption] : [];

    const liveUserOptions = useMemo<UserOption[] | undefined>(() => {
        if (!data) {
            return undefined;
        }

        let includesCurrentlyAssignedUser = false;
        const options = data?.me?.organization?.members?.nodes.map(
            (user: User) => {
                const isCurrentUser = user.id === nfcTag.user?.id;
                includesCurrentlyAssignedUser =
                    includesCurrentlyAssignedUser || isCurrentUser;
                return {
                    id: user.id,
                    user: user,
                    nfcTags: user.nfcTags,
                    state: isCurrentUser
                        ? '0-currently-assigned'
                        : user.nfcTags && user.nfcTags.length > 0
                        ? '2-assigned'
                        : '1-unassigned'
                } as UserOption;
            }
        );

        if (!includesCurrentlyAssignedUser && initialUserOption) {
            options.push(initialUserOption);
        }

        return orderBy(options, ['state', 'user.name'], ['asc', 'asc']);
    }, [data]);

    const userOptions = liveUserOptions || initialUserOptions;

    useEffect(() => {
        if (open) {
            fetchUsers();
        }
    }, [open]);

    // Trigger onDirty when the chosen user changes
    useEffect(() => {
        onDirty(!!chosenUserId && nfcTag.user?.id !== chosenUserId);
    }, [nfcTag, onDirty, chosenUserId]);

    const { enqueueSnackbar } = useSnackbar();

    // TODO add check if onSave is changing the user and trigger a confirmation if the old user is still active as he or
    //  she will be unable to use the terminal from now on (if no other id card or authentication method is active)

    const handleSave = async () => {
        if (!chosenUserId) {
            enqueueSnackbar('Bitte wähle eine Mitarbeiter:in aus', {
                variant: 'warning',
                autoHideDuration: 5000
            });
            return;
        }

        if (nfcTag.user && chosenUserId === nfcTag.user.id) {
            executeSafe(onSave);
            return;
        }

        if (working) {
            return;
        }
        setWorking(true);

        const chosenUser = userOptions.find(
            option => option.id === chosenUserId
        );
        const userName = chosenUser?.user?.name || 'Unbekannt';

        return mutationAssignNfcTag({
            variables: { id: nfcTag.id, userId: chosenUserId },
            refetchQueries: [QUERY_USERS_FOR_NFC_TAG_ASSIGNMENT]
        })
            .then(() => {
                enqueueSnackbar(
                    `ID-Karte „${nfcTag.label}“ wurde Mitarbeiter:in „${userName}“ zugewiesen`,
                    {
                        variant: 'success',
                        autoHideDuration: 5000
                    }
                );
                executeSafe(onSave);
            })
            .catch(error => {
                const apiErrorCode = getApiErrorCode(error);

                if (apiErrorCode === 'nfc_tag_not_found') {
                    enqueueSnackbar(
                        'Diese ID-Karte wurde zwischenzeitlich deaktiviert.',
                        {
                            variant: 'warning',
                            autoHideDuration: 5000
                        }
                    );
                    executeSafe(onSave);
                    return;
                }

                enqueueSnackbar(
                    `Fehler beim Speichern der Zuweisung: ${error.message}`,
                    {
                        variant: 'error',
                        autoHideDuration: 5000
                    }
                );
            })
            .finally(() => {
                setWorking(false);
            });
    };

    return (
        <EditNfcTagUserAssignmentPopoverCt>
            <h2 style={{ marginTop: 0, marginBottom: 8 }}>
                „{nfcTag.label}“ {nfcTag.user ? 'neu ' : ''}zuweisen
            </h2>
            <Autocomplete
                id="nfc-tag-user-assignment-popover-input"
                sx={{ marginRight: 8, marginTop: 0 }}
                fullWidth
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                groupBy={option =>
                    option.state === '0-currently-assigned'
                        ? 'Aktuelle Zuweisung'
                        : option.state === '2-assigned'
                        ? 'Mit Zuweisung'
                        : 'Ohne Zuweisung'
                }
                isOptionEqualToValue={(option1, option2) =>
                    option1?.id === option2?.id
                }
                getOptionLabel={option =>
                    getUserName(option.user) +
                    (option.user.state === 'INVITED'
                        ? ' (Eingeladen)'
                        : option.user.state === 'INACTIVE'
                        ? ' (Deaktiviert)'
                        : '')
                }
                renderOption={(props, option) => {
                    const { ...optionProps } = props;
                    return (
                        <Box
                            key={option.id}
                            component="li"
                            {...optionProps}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8
                            }}>
                            <UserName>{getUserName(option.user)}</UserName>
                            <UserStateChip user={option.user} />
                            {option.nfcTags
                                ? option.nfcTags.map(nfcTag => (
                                      <NfcTagLabel key={nfcTag.id}>
                                          {nfcTag.label}
                                      </NfcTagLabel>
                                  ))
                                : null}
                        </Box>
                    );
                }}
                options={userOptions}
                loading={loading}
                value={
                    chosenUserId
                        ? userOptions.find(option => option.id === chosenUserId)
                        : null
                }
                onChange={(event, newValue) =>
                    setChosenUserId(newValue?.id || null)
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        hiddenLabel
                        variant="standard"
                        fullWidth
                        placeholder={'Mitarbeiter:in wählen'}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
            />
            <div
                style={{
                    marginTop: 20,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row-reverse'
                }}>
                <PrimaryButton onClick={handleSave} disabled={working}>
                    Mitarbeiter:in zuweisen
                </PrimaryButton>
                <SecondaryButton onClick={onAbort} disabled={working}>
                    Abbrechen
                </SecondaryButton>
            </div>
        </EditNfcTagUserAssignmentPopoverCt>
    );
};
