import styled from '@emotion/styled';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Popover, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useUser } from '../core/auth/useUser';
import { NAV_BREAK_DESKTOP } from '../core/theme/Breakpoints';
import { Colors } from '../core/theme/Colors';
import { NotificationsPopoverContent } from './NotificationsPopoverContent';

const MainNavNotificationsButton = styled.button`
    position: relative;
    z-index: 1;
    border: 0;
    outline: 0;
    background: none;
    margin: -2px 0;
    padding: 2px;
    cursor: pointer;

    .not-icon-ct {
        height: 30px;
        width: 30px;
        display: block;
        border-radius: 15px;
        padding: 3px;
        background-color: white;
        transition: background-color 0.2s;
    }

    svg {
        display: block;
        width: 24px;
        height: 24px;
        fill: ${Colors.SecondaryLight};
    }

    @media screen and (max-width: ${NAV_BREAK_DESKTOP}px) {
        padding: 0;
        margin: 4px;

        .not-icon-ct {
            width: 44px;
            height: 44px;
            padding: 8px;
            border-radius: 22px;
        }

        svg {
            width: 28px;
            height: 28px;
        }
    }

    &:hover,
    &:focus {
        .not-icon-ct {
            background: ${Colors.Primary};
        }

        svg {
            fill: white;
        }
    }

    &:active {
        .not-icon-ct {
            background: ${Colors.PrimaryDark};
        }
    }

    &.has-unread {
        svg {
            fill: ${Colors.Red};
        }
    }

    &.is-open {
        .not-icon-ct {
            background: ${Colors.Primary};
        }

        svg {
            fill: white;
        }
    }
`;

const NotificationPopover = styled(Popover)`
    .MuiPopover-paper {
        margin-top: 4px;
        border-radius: 10px;
    }
`;

export const MainNavigationNotificationsButton = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const user = useUser();
    const unreadNotificationsCount = user?.unreadNotificationsCount;
    const hasUnreadNotifications =
        !!unreadNotificationsCount && unreadNotificationsCount > 0;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        event.currentTarget.blur();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'notifications-popover' : undefined;

    const Icon = hasUnreadNotifications
        ? NotificationsActiveIcon
        : NotificationsIcon;

    const buttonClasses: string[] = [];
    if (hasUnreadNotifications) {
        buttonClasses.push('has-unread');
    }

    if (open) {
        buttonClasses.push('is-open');
    }

    return (
        <>
            <Tooltip title={'Benachrichtigungen'} arrow>
                <MainNavNotificationsButton
                    aria-label="Benachrichtungen"
                    aria-describedby={id}
                    onClick={handleClick}
                    className={buttonClasses.join(' ')}>
                    <span className={'not-icon-ct'}>
                        <Icon aria-hidden />
                    </span>
                    <UnreadNotificationsCountBadge
                        count={unreadNotificationsCount}
                    />
                </MainNavNotificationsButton>
            </Tooltip>
            <NotificationPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                keepMounted>
                <NotificationsPopoverContent
                    hasUnreadNotifications={hasUnreadNotifications}
                    onClose={handleClose}
                    open={open}
                />
            </NotificationPopover>
        </>
    );
};

const CountBadge = styled.span`
    position: absolute;
    z-index: 2;
    top: 11px;
    left: 0;
    right: 1px;
    height: 12px;
    font-size: 9px;
    line-height: 12px;
    color: white;
    text-align: center;
    font-weight: bold;

    .is-open & {
        color: ${Colors.Primary};
    }
`;

const UnreadNotificationsCountBadge = ({ count }) => {
    if (!count || count < 2) {
        return null;
    }

    const countLabel = count > 9 ? '✱' : count;

    return <CountBadge>{countLabel}</CountBadge>;
};
