import styled from '@emotion/styled';
import React from 'react';
import WelcomePng from '../../assets/images/welcome.png';
import { Colors } from '../theme/Colors';

const Ct = styled.div<{ size?: 'small' | 'large' }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => (props.size === 'small' ? '25' : '66')}vh;
    width: 100%;
`;

const Box = styled.div`
    padding: 24px;
    width: 100%;
`;

const Image = styled.img<{ size?: 'small' | 'large' }>`
    display: block;
    width: ${props => (props.size === 'small' ? '25' : '50')}%;
    max-width: ${props => (props.size === 'small' ? '150' : '250')}px;
    margin: auto;
`;

const LabelCt = styled.div`
    margin-top: 24px;
`;

const Label = styled.div`
    color: ${Colors.Secondary};
    font-size: 20px;
    line-height: 32px;
    text-align: center;
`;

const AdditionalInfoCt = styled.div`
    margin: 10px auto 0;
    max-width: 343px;
`;

const AdditionalInfo = styled.div`
    color: #7b7b80;
    text-align: center;
`;

export const NothingHereToSee = ({
    label,
    additionalInfo,
    size
}: {
    label: string;
    additionalInfo?: any;
    size?: 'small' | 'large';
}) => {
    return (
        <Ct size={size}>
            <Box>
                <Image src={WelcomePng} alt="" size={size} />
                <LabelCt>
                    <Label>
                        {label || 'Hier gibt es noch nichts zu sehen.'}
                    </Label>
                </LabelCt>
                {additionalInfo && (
                    <AdditionalInfoCt>
                        <AdditionalInfo>{additionalInfo}</AdditionalInfo>
                    </AdditionalInfoCt>
                )}
            </Box>
        </Ct>
    );
};

NothingHereToSee.defaultProps = {
    isFetching: false
};
