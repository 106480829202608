import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import {
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    NativeSelect
} from '@mui/material';
import React, { useState } from 'react';
import { ListFilterBar } from '../../core/components/list/ListFilterBar';
import { ListWithFilters } from '../../core/components/list/ListWithFilters';
import { Colors } from '../../core/theme/Colors';
import { OrganizationMembersTable } from '../../organization/components/OrganizationMembersTable';
import { UserState } from '../../user/userState';

const StyledInput = styled(Input)`
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 17px !important;

    &:before {
        border-bottom-color: ${Colors.SecondaryLight};
    }

    input {
        font-size: 20px;
        line-height: 25px;
        height: 25px;
        font-weight: bold;
        padding-top: 0;
        padding-bottom: 0;
        color: ${Colors.TextLight};

        &::placeholder {
            color: ${Colors.SecondaryLight};
            opacity: 1;
        }
    }
`;

const StyledNativeSelect = styled(NativeSelect)`
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 17px !important;
    margin-right: 24px;

    &:before {
        border-bottom-color: ${Colors.SecondaryLight};
    }

    select {
        font-size: 20px;
        line-height: 25px;
        height: 25px;
        font-weight: bold;
        padding-top: 0;
        padding-bottom: 0;
        color: ${Colors.TextLight};

        &::placeholder {
            color: ${Colors.SecondaryLight};
            opacity: 1;
        }

        &.selected--active {
            color: ${Colors.GreenDark};
        }

        option {
            font-weight: normal;
            color: unset;
        }
    }

    svg {
        top: calc(50% - 0.5em);
    }
`;

export const EmployeeList = () => {
    const [nameQuery, setNameQuery] = useState<string | undefined>('');
    const [stateFilter, setStateFilter] = useState<UserState>(UserState.ACTIVE);

    return (
        <ListWithFilters>
            <ListFilterBar>
                <FormControl>
                    <InputLabel
                        shrink
                        disableAnimation
                        htmlFor="employee-user-status">
                        Status
                    </InputLabel>
                    <StyledNativeSelect
                        inputProps={{
                            id: 'employee-user-status',
                            onChange: e => {
                                setStateFilter(UserState[e.target.value]);
                            },
                            value: stateFilter,
                            className:
                                stateFilter === UserState.ACTIVE
                                    ? 'selected--active'
                                    : ''
                        }}>
                        <option value={UserState.ACTIVE}>Aktiv</option>
                        <option value={UserState.INACTIVE}>Deaktiviert</option>
                    </StyledNativeSelect>
                </FormControl>
                <FormControl>
                    <InputLabel
                        shrink
                        disableAnimation
                        htmlFor="search-member-by-name-input">
                        Name
                    </InputLabel>
                    <StyledInput
                        id="search-member-by-name-input"
                        value={nameQuery}
                        onChange={event => setNameQuery(event.target.value)}
                        placeholder="Name suchen"
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon
                                    sx={{
                                        fontSize: 22,
                                        color: Colors.SecondaryLight
                                    }}
                                />
                            </InputAdornment>
                        }
                        autoFocus
                    />
                </FormControl>
            </ListFilterBar>

            <OrganizationMembersTable
                nameQuery={nameQuery}
                stateFilter={stateFilter}
            />
        </ListWithFilters>
    );
};
