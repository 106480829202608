import { useLazyQuery, useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import {
    Alert,
    Autocomplete,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from '@mui/material';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { SecondaryButton } from '../../common/components/button/SecondaryButton';
import { getApiErrorCode } from '../../core/api/error';
import { User } from '../../core/auth/user';
import { Colors } from '../../core/theme/Colors';
import { NfcTag } from '../../nfcTag/nfcTag';
import {
    MUTATION_ASSIGN_NFC_TAG,
    QUERY_NFC_TAGS_FOR_USER_ASSIGNMENT
} from '../../nfcTag/queries';
import { getUserName } from '../../user';
import { UserStateChip } from '../../user/UserStateChip';
import { executeSafe } from '../../utils';

const EditNfcTagUserAssignmentPopoverCt = styled.div`
    padding: 16px;
    width: 400px;
`;

const UserName = styled.span`
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 0;
`;

const NfcTagLabel = styled.span`
    display: block;
    padding: 2px 6px 1px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 11px;
    font-family: monospace;
    font-weight: 600;
    color: ${Colors.SecondaryDarker};
    background: white;
    border: 1px solid ${Colors.SecondaryLightest};
    border-radius: 2px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
    text-overflow: ellipsis;
`;

type NfcTagOption = {
    id: string;
    nfcTag: NfcTag;
    user: User;
    status: '0-free' | '1-assigned-inactive' | '2-assigned';
};

export const AddUserNfcTagAssignmentPopoverForm = ({
    user,
    onAdd,
    onAbort,
    onDirty
}: {
    user: User;
    onAdd: () => void;
    onAbort: () => void;
    onDirty: (dirty: boolean) => void;
}) => {
    const [working, setWorking] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [fetchNfcTags, { loading, data }] = useLazyQuery(
        QUERY_NFC_TAGS_FOR_USER_ASSIGNMENT
    );
    const [chosenNfcTagId, setChosenNfcTagId] = useState<string | undefined>();
    const [showConfirmationDialog, setShowConfirmationDialog] =
        useState<NfcTag | null>(null);

    const [mutationAssignNfcTag] = useMutation(MUTATION_ASSIGN_NFC_TAG);

    const nfcTagOptions = useMemo<NfcTagOption[]>(() => {
        if (!data) {
            return [];
        }

        const options = data?.me?.organization?.nfcTags?.nodes.map(
            (nfcTag: NfcTag) =>
                ({
                    id: nfcTag.id,
                    user: nfcTag.user,
                    nfcTag: nfcTag,
                    status: !nfcTag.user
                        ? '0-free'
                        : nfcTag.user.state === 'INACTIVE'
                        ? '1-assigned-inactive'
                        : '2-assigned'
                } as NfcTagOption)
        );

        return orderBy(
            options,
            ['status', 'user.state', 'user.name'],
            ['asc', 'asc', 'asc']
        );
    }, [data]);

    useEffect(() => {
        if (open) {
            fetchNfcTags();
        }
    }, [open]);

    // Trigger onDirty when something was chosen
    useEffect(() => {
        onDirty(!!chosenNfcTagId);
    }, [onDirty, chosenNfcTagId]);

    const { enqueueSnackbar } = useSnackbar();

    const assignNfcTag = async (chosenNfcTag: NfcTag) => {
        if (working) {
            return;
        }
        setWorking(true);

        const nfcTagLabel = chosenNfcTag.label || 'Unbekannt';

        return mutationAssignNfcTag({
            variables: { id: chosenNfcTagId, userId: user.id }
        })
            .then(() => {
                enqueueSnackbar(
                    `ID-Karte „${nfcTagLabel}“ wurde Mitarbeiter:in „${getUserName(
                        user
                    )}“ zugewiesen`,
                    {
                        variant: 'success',
                        autoHideDuration: 5000
                    }
                );
                executeSafe(onAdd);
            })
            .catch(error => {
                const apiErrorCode = getApiErrorCode(error);

                if (apiErrorCode === 'nfc_tag_not_found') {
                    enqueueSnackbar(
                        'Diese ID-Karte wurde zwischenzeitlich deaktiviert.',
                        {
                            variant: 'warning',
                            autoHideDuration: 5000
                        }
                    );
                    executeSafe(onAdd);
                    return;
                }

                enqueueSnackbar(
                    `Fehler beim Speichern der Zuweisung: ${error.message}`,
                    {
                        variant: 'error',
                        autoHideDuration: 5000
                    }
                );
            })
            .finally(() => {
                setWorking(false);
            });
    };

    const handleSave = async () => {
        if (!chosenNfcTagId) {
            enqueueSnackbar('Bitte wähle eine ID-Karte aus', {
                variant: 'warning',
                autoHideDuration: 5000
            });
        }

        const chosenNfcTag = nfcTagOptions.find(
            option => option.id === chosenNfcTagId
        );

        if (!chosenNfcTag) {
            enqueueSnackbar(
                'Die ausgewählte ID-Karte konnte nicht gefunden werden. Zuweisung nicht möglich',
                {
                    variant: 'error'
                }
            );
            return;
        }

        // If the chosen NFC tag is already assigned to the user that is not inactive, we show a confirmation dialog (just to be sure)
        if (
            chosenNfcTag.user &&
            chosenNfcTag.user.id !== user.id &&
            chosenNfcTag.user.state !== 'INACTIVE'
        ) {
            setShowConfirmationDialog(chosenNfcTag.nfcTag);
            return;
        }

        return assignNfcTag(chosenNfcTag.nfcTag);
    };

    const onConfirm = async () => {
        return assignNfcTag(showConfirmationDialog!).finally(() => {
            setShowConfirmationDialog(null);
        });
    };

    return (
        <EditNfcTagUserAssignmentPopoverCt>
            <h2 style={{ marginTop: 0, marginBottom: 8 }}>
                Neue ID-Kartenzuweisung für „{getUserName(user)}“
            </h2>
            <Autocomplete
                id="nfc-tag-user-assignment-popover-input"
                sx={{ marginRight: 8, marginTop: 0 }}
                fullWidth
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                groupBy={option =>
                    option.status === '0-free'
                        ? 'Frei'
                        : option.status === '1-assigned-inactive'
                        ? 'Zugewiesen (Mitarbeiter deaktiviert)'
                        : 'Zugewiesen'
                }
                isOptionEqualToValue={(option1, option2) =>
                    option1.id === option2.id
                }
                getOptionLabel={option =>
                    option.nfcTag.label +
                    (option.user
                        ? ' (Bisher: ' + getUserName(option.user) + ')'
                        : '')
                }
                renderOption={(props, option) => {
                    const { ...optionProps } = props;
                    return (
                        <Box
                            key={option.id}
                            component="li"
                            {...optionProps}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8
                            }}>
                            <NfcTagLabel key={option.id}>
                                {option.nfcTag.label || (
                                    <span
                                        style={{
                                            color: Colors.SecondaryLight
                                        }}>
                                        ?
                                    </span>
                                )}
                            </NfcTagLabel>
                            {!!option.user && (
                                <>
                                    <UserName>{option.user?.name}</UserName>
                                    <UserStateChip user={option.user} />
                                </>
                            )}
                        </Box>
                    );
                }}
                options={nfcTagOptions}
                loading={loading}
                value={
                    chosenNfcTagId
                        ? nfcTagOptions.find(
                              option => option.id === chosenNfcTagId
                          )
                        : null
                }
                onChange={(event, newValue) => setChosenNfcTagId(newValue?.id)}
                renderInput={params => (
                    <TextField
                        {...params}
                        hiddenLabel
                        variant="standard"
                        fullWidth
                        placeholder={'ID-Karte wählen'}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                    />
                )}
            />
            <div
                style={{
                    marginTop: 20,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row-reverse'
                }}>
                <PrimaryButton onClick={handleSave} disabled={working}>
                    ID-Karte zuweisen
                </PrimaryButton>
                <SecondaryButton onClick={onAbort} disabled={working}>
                    Abbrechen
                </SecondaryButton>
            </div>
            {showConfirmationDialog && (
                <Dialog
                    open={!!showConfirmationDialog}
                    keepMounted={false}
                    onClose={() => setShowConfirmationDialog(null)}
                    aria-labelledby="nfc-unassign-confirmation-title"
                    aria-describedby="nfc-unassign-confirmation-description">
                    <DialogTitle id="nfc-unassign-confirmation-title">
                        ID-Karte neu zuweisen
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="nfc-unassign-confirmation-description">
                            ID-Karte{' '}
                            <strong>„{showConfirmationDialog.label}“</strong>{' '}
                            ist zur Zeit Mitarbeiter:in{' '}
                            <strong>
                                „{getUserName(showConfirmationDialog.user)}“
                            </strong>
                            zugewiesen. Möchtest du die Zuweisung trotzdem
                            durchführen?
                        </DialogContentText>
                        <Alert severity={'warning'} style={{ marginTop: 16 }}>
                            Hierdurch verliert{' '}
                            {getUserName(showConfirmationDialog.user)} den
                            Zugriff auf die Dajeh-Terminals wenn keine weitere
                            Kartenzuweisung besteht.
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton
                            onClick={() => setShowConfirmationDialog(null)}
                            autoFocus>
                            Abbrechen
                        </SecondaryButton>
                        <PrimaryButton onClick={onConfirm}>
                            Karte neu zuweisen
                        </PrimaryButton>
                    </DialogActions>
                </Dialog>
            )}
        </EditNfcTagUserAssignmentPopoverCt>
    );
};
