import styled from '@emotion/styled';
import { Badge } from '@mui/material';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React, { PropsWithChildren } from 'react';
import { TimeEntryFormValues } from './TimeEntryFormValues';

const StyledBadge = styled(Badge)`
    .BaseBadge-badge {
        color: white;
        font-weight: 600;
    }
`;

export const TimeEntryFormDayBarrierBadge = ({
    children
}: PropsWithChildren<any>) => {
    const {
        values: { startDate, endDate }
    } = useFormikContext<TimeEntryFormValues>();

    if (!endDate) {
        return children;
    }

    const startDateTime = DateTime.fromISO(startDate);
    const endDateTime = DateTime.fromISO(endDate);

    let text: string | undefined = undefined;
    if (
        startDateTime.isValid &&
        endDateTime.isValid &&
        !startDateTime.hasSame(endDateTime, 'day')
    ) {
        const daysDiff = Math.ceil(
            endDateTime.diff(startDateTime, 'days').days
        );
        text = `+${daysDiff}`;
    }

    return (
        <StyledBadge badgeContent={text} color="primary">
            {children}
        </StyledBadge>
    );
};
