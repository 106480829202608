import { generatePath } from 'react-router';
import { Permission } from '../auth/permission';

export const Paths = {
    Home: '/',

    /*
     * Login, password reset and other public pages
     */
    Login: '/login',
    RequestPasswordReset: '/reset-password',
    ResetPassword: '/reset-password/:token',

    /* Organization activation page */
    OrganizationActivationPage: '/activate/:token',

    /* Invitation flow */
    AcceptInvitation: '/inv/:token',

    /*
     * Personal
     */
    WorkTimeTracking: '/time-tracking',

    /*
     * Reports
     */
    OrganizationWorkTimeReport: '/reports/work-time/:month?',
    OrganizationWorkTimeReportWOP: '/reports/work-time',

    /*
     * User
     */
    User: '/me',
    UserSettings: '/me/settings',
    UserNotifications: '/me/notifications',

    /*
     * ID Tags
     */
    IdTags: '/id-tags',

    /*
     * Employees
     */
    EmployeeList: '/employees',
    EmployeeBase: '/employees/:employeeId',
    EmployeeProfile: '/employees/:employeeId/profile',
    EmployeeNotes: '/employees/:employeeId/notes',
    EmployeeTimeTracking: '/employees/:employeeId/time-tracking',
    EmployeeReports: '/employees/:employeeId/reports',
    EmployeeWorkTimeReport: '/employees/:employeeId/reports/work-time',
    EmployeeOnboardingDocument: '/employees/:employeeId/onboarding-document',

    /*
     * Help
     */
    Help: '/help',
    HelpEmployees: '/help/employees'
};

export const PathPermissions = {
    OrganizationWorkTimeReport: [Permission.Read_Team_Time_Report],
    Employees: [Permission.Manage_Users]
};

export function getEmployeeOnboardingDocumentUrl(employeeId: string) {
    return generatePath(Paths.EmployeeOnboardingDocument, { employeeId });
}
