import styled from '@emotion/styled';
import { Colors } from '../../core/theme/Colors';

export const NfcTagLabel = styled.span`
    display: inline-block;
    padding: 2px 12px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 13px;
    font-family: monospace;
    font-weight: 600;
    color: ${Colors.SecondaryDarker};
    background: white;
    border: 1px solid ${Colors.SecondaryLightest};
    border-radius: 3px;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
    text-overflow: ellipsis;
`;

export const NfcTagLabelPlain = styled.span`
    white-space: nowrap;
    overflow: hidden;
    font-size: 13px;
    font-family: monospace;
    font-weight: 600;
    color: ${Colors.SecondaryDarker};
    text-overflow: ellipsis;
`;
