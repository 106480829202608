import styled from '@emotion/styled';
import { Breakpoints } from '../core/theme/Breakpoints';

export const PageHeadlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    > * + * {
        margin-left: 8px;
    }

    h1 {
        margin-bottom: 0 !important;
    }

    @media (min-width: ${Breakpoints.md}px) {
        > * + * {
            margin-left: 10px;
        }
    }

    @media (min-width: ${Breakpoints.lg}px) {
        > * + * {
            margin-left: 12px;
        }
    }
`;
