import styled from '@emotion/styled';
import { Colors } from '../../theme/Colors';

export const SubPageSectionHeadline = styled.h3<{ first?: boolean }>`
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: bold;
    margin-top: ${props => (props.first ? 0 : '16px')};
    margin-bottom: 12px;

    @media (min-width: 600px) {
        gap: 6px;
        font-size: 18px;
        margin-bottom: 14px;
        margin-top: ${props => (props.first ? 0 : '20px')};
    }

    @media (min-width: 960px) {
        gap: 8px;
        font-size: 22px;
        margin-top: ${props => (props.first ? 0 : '24px')};
        margin-bottom: 16px;
    }
`;
