import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Popover,
    Tooltip
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { generatePath } from 'react-router';
import { NavLink } from 'react-router-dom';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { SecondaryButton } from '../../common/components/button/SecondaryButton';
import { NotAvailable } from '../../common/components/NotAvailable';
import { getApiErrorCode } from '../../core/api/error';
import { Paths } from '../../core/navigation/paths';
import { Colors } from '../../core/theme/Colors';
import { shakeElement } from '../../core/theme/utils';
import { getUserName } from '../../user';
import { UserAvatar } from '../../user/UserAvatar';
import { UserStateChip } from '../../user/UserStateChip';
import { executeSafe } from '../../utils';
import {
    MUTATION_UNASSIGN_NFC_TAG,
    QUERY_USERS_FOR_NFC_TAG_ASSIGNMENT
} from '../queries';
import { EditNfcTagUserAssignmentPopoverForm } from './EditNfcTagUserAssignmentPopoverForm';

const StyledIconButton = styled(IconButton)`
    margin-top: -4px;
    margin-bottom: -4px;

    svg {
        width: 18px;
        height: 18px;
    }
`;

const POPOVER_ID = 'nfc-tag-edit-user-assignment-inline-popover';

const Ct = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    .assigned-nfc-tag-ct {
        display: flex;
        gap: 8px;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 24px;
        padding: 8px;
        margin: -3px 0 -3px -8px;
        background-color: transparent;
        transition: border-color 0.2s, background 0.2s;
    }

    .actions-ct {
        padding-left: 8px;
        border-left: 1px solid ${Colors.SecondaryLightest};
        margin-left: 8px;
        opacity: 0;
        transition: opacity 0.2s;
    }

    &:hover {
        .assigned-nfc-tag-ct {
            border-color: ${Colors.SecondaryLightest};
            background: white;
        }

        .actions-ct {
            opacity: 1;
        }
    }
`;

const UserLink = styled(NavLink)`
    color: ${Colors.Text};
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        color: black;
        text-decoration: underline;
    }
`;

export const EditableNfcTagUserAssignment = ({ nfcTag, onChange }) => {
    const [anchorEl, setAnchorEl] =
        React.useState<HTMLButtonElement | null>(null);

    const ctRef = useRef<any>();

    const [isWorking, setWorking] = useState(false);
    const [isDirty, setDirty] = useState(false);
    const [showConfirmUnassignDialog, setShowConfirmUnassignDialog] =
        useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const [mutationUnassignNfcTag] = useMutation(MUTATION_UNASSIGN_NFC_TAG);

    const onAssignUser = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(ctRef.current);
    };

    const onUnassignNfcTagClick = () => {
        if (!nfcTag.user) {
            return;
        }

        // If a user is inactive, we can safely unassign the NFC tag without confirmation
        if (nfcTag.user.state === 'INACTIVE') {
            unassignNfcTag();
        } else {
            setShowConfirmUnassignDialog(true);
        }
    };

    const onConfirmUnassignNfcTagClick = () => {
        unassignNfcTag();
    };

    const unassignNfcTag = () => {
        if (isWorking) {
            return;
        }
        setWorking(true);

        const unassignedUser = nfcTag.user;
        mutationUnassignNfcTag({
            variables: { id: nfcTag.id },
            refetchQueries: [QUERY_USERS_FOR_NFC_TAG_ASSIGNMENT]
        })
            .then(_ => {
                enqueueSnackbar(
                    `Zuweisung von ${getUserName(
                        unassignedUser
                    )} zu ID-Karte „${nfcTag.label}“ wurde aufgehoben`,
                    {
                        variant: 'success',
                        autoHideDuration: 5000
                    }
                );
                executeSafe(onChange);
            })
            .catch(error => {
                const apiErrorCode = getApiErrorCode(error);

                if (apiErrorCode === 'nfc_tag_not_assigned') {
                    enqueueSnackbar(
                        `Zuweisung von ${getUserName(
                            unassignedUser
                        )} zu ID-Karte „${
                            nfcTag.label
                        }“ ist bereits aufgehoben`,
                        {
                            variant: 'success',
                            autoHideDuration: 5000
                        }
                    );
                    executeSafe(onChange);
                    return;
                }

                if (apiErrorCode === 'nfc_tag_not_found') {
                    enqueueSnackbar(
                        `ID-Karte „${nfcTag.label}“ wurde zwischenzeitlich deaktiviert.`,
                        {
                            variant: 'warning',
                            autoHideDuration: 5000
                        }
                    );
                    executeSafe(onChange);
                    return;
                }

                enqueueSnackbar(
                    `Fehler beim Löschen der Mitarbeiterzuweisung: ${error.message}`,
                    {
                        variant: 'error',
                        autoHideDuration: 5000
                    }
                );
            })
            .finally(() => {
                setWorking(false);
                setShowConfirmUnassignDialog(false);
            });
    };

    const shakeEditPopover = () => {
        const element = document.getElementById(POPOVER_ID);
        shakeElement(element);
    };

    const closeInlineForm = () => {
        setAnchorEl(null);
    };

    const onSave = () => {
        closeInlineForm();
        executeSafe(onChange);
    };

    const handleClose = (ignoreDirty: boolean) => {
        if (isDirty && !ignoreDirty) {
            shakeEditPopover();
            return;
        }
        closeInlineForm();
    };

    const popoverIsOpen = Boolean(anchorEl);
    const popoverId = popoverIsOpen ? POPOVER_ID : undefined;

    return (
        <Ct ref={ctRef}>
            {nfcTag?.user ? (
                <div className={'assigned-nfc-tag-ct'}>
                    <Tooltip title={nfcTag.user?.email}>
                        <span
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                gap: 8
                            }}>
                            <UserAvatar user={nfcTag.user} small greyscale />
                            <UserLink
                                to={generatePath(Paths.EmployeeProfile, {
                                    employeeId: nfcTag.user.id
                                })}>
                                {nfcTag.user?.firstName} {nfcTag.user?.lastName}
                            </UserLink>
                        </span>
                    </Tooltip>
                    <UserStateChip user={nfcTag.user} showActive={false} />
                    <div className={'actions-ct'}>
                        <Tooltip title={'Zuweisung ändern'}>
                            <StyledIconButton
                                aria-label="Zuweisung ändern"
                                onClick={onAssignUser}>
                                <EditIcon />
                            </StyledIconButton>
                        </Tooltip>
                        <Tooltip
                            title={'Zuweisung aufheben / ID-Karte freigeben'}>
                            <StyledIconButton
                                aria-label="Zuweisung aufheben"
                                onClick={onUnassignNfcTagClick}>
                                <PersonOffIcon
                                    style={{
                                        fill: Colors.RedDark,
                                        transform: 'scale(-1, 1)',
                                        transformOrigin: 'center'
                                    }}
                                />
                            </StyledIconButton>
                        </Tooltip>
                    </div>
                </div>
            ) : (
                <>
                    <span style={{ padding: '0 4px', marginRight: 2 }}>
                        <NotAvailable
                            label={'n.z.'}
                            tooltipTitle={'Nicht zugewiesen'}
                        />
                    </span>
                    <Button
                        onClick={onAssignUser}
                        color={'primary'}
                        size={'small'}>
                        <PersonAddAlt1Icon
                            style={{
                                width: 20,
                                height: 20,
                                marginRight: 8
                            }}
                        />
                        Mitarbeiter zuweisen
                    </Button>
                </>
            )}
            <Popover
                id={popoverId}
                open={popoverIsOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                onClose={() => handleClose(false)}>
                <EditNfcTagUserAssignmentPopoverForm
                    nfcTag={nfcTag}
                    onSave={onSave}
                    onAbort={() => handleClose(true)}
                    onDirty={isDirty => setDirty(isDirty)}
                />
            </Popover>
            {nfcTag.user && (
                <Dialog
                    open={showConfirmUnassignDialog}
                    keepMounted={false}
                    onClose={() => setShowConfirmUnassignDialog(false)}
                    aria-labelledby="nfc-unassign-confirmation-title"
                    aria-describedby="nfc-unassign-confirmation-description">
                    <DialogTitle id="nfc-unassign-confirmation-title">
                        Mitarbeiterzuweisung aufheben
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="nfc-unassign-confirmation-description">
                            Möchten Sie die Mitarbeiterzuweisung der ID-Karte „
                            <strong>{nfcTag.label}</strong>“ zu Mitarbeiter{' '}
                            <NavLink
                                target={'_blank'}
                                to={generatePath(Paths.EmployeeProfile, {
                                    employeeId: nfcTag.user.id
                                })}
                                style={{ fontWeight: 600 }}>
                                {nfcTag.user.firstName} {nfcTag.user.lastName}
                            </NavLink>{' '}
                            wirklich aufheben?
                        </DialogContentText>
                        <Alert severity={'warning'} style={{ marginTop: 16 }}>
                            Hierdurch verliert {getUserName(nfcTag.user)} den
                            Zugriff auf die Dajeh-Terminals.
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton
                            onClick={() => setShowConfirmUnassignDialog(false)}
                            autoFocus>
                            Abbrechen
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={() => onConfirmUnassignNfcTagClick()}>
                            Mitarbeiterzuweisung aufheben
                        </PrimaryButton>
                    </DialogActions>
                </Dialog>
            )}
        </Ct>
    );
};
