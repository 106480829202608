import styled from '@emotion/styled';
import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { NotAvailable } from '../../common/components/NotAvailable';
import { Colors } from '../../core/theme/Colors';
import { getOfficialDateTimeLabel } from '../../timeTracking/time';
import { NfcTag } from '../nfcTag';
import { EditableNfcTagLabel } from './EditableNfcTagLabel';
import { EditableNfcTagUserAssignment } from './EditableNfcTagUserAssignment';
import { NfcTagStatus } from './NfcTagStatus';

const StyledTableRow = styled(TableRow)`
    &.is-inactive,
    &.is-invited {
        td {
            color: ${Colors.TextLight};
        }
    }

    &:hover {
        td {
            background: ${Colors.Background};
        }
    }
`;

type NfcTagsTableRowProps = {
    index: number;
    nfcTag: Partial<NfcTag>;
    onNfcTagChange: () => void;
};

export const NfcTagsTableRow = ({
    index,
    nfcTag,
    onNfcTagChange
}: NfcTagsTableRowProps) => {
    return (
        <StyledTableRow key={nfcTag.id}>
            <TableCell
                style={{
                    textAlign: 'center',
                    fontWeight: '500',
                    color: Colors.SecondaryLight,
                    paddingRight: 0
                }}>
                {index + 1}
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
                <NfcTagStatus nfcTag={nfcTag} />
            </TableCell>
            <TableCell>
                <EditableNfcTagLabel
                    nfcTag={nfcTag}
                    onChange={onNfcTagChange}
                />
            </TableCell>
            <TableCell>
                <EditableNfcTagUserAssignment
                    nfcTag={nfcTag}
                    onChange={onNfcTagChange}
                />
            </TableCell>
            <TableCell style={{ paddingLeft: 0 }}>
                {nfcTag.assignedAt ? (
                    <span style={{ fontSize: 14, color: Colors.TextLight }}>
                        {getOfficialDateTimeLabel(nfcTag.assignedAt)}
                    </span>
                ) : (
                    <NotAvailable showTooltip={false} label={'-'} />
                )}
            </TableCell>
        </StyledTableRow>
    );
};
