import styled from '@emotion/styled';
import React from 'react';
import { Breakpoints } from '../../theme/Breakpoints';
import { Colors } from '../../theme/Colors';

const Title = styled.h1`
    margin-top: 0;
    margin-bottom: 26px;
    font-size: 24px;
    line-height: 1.3;
    font-weight: 700;

    svg {
        float: left;
        font-size: inherit;
        margin-right: 0.3em;
        transform: translateY(0.1em);
        fill: ${Colors.SecondaryLighter};
    }

    @media (min-width: ${Breakpoints.md}px) {
        font-size: 28px;
    }

    @media (min-width: ${Breakpoints.lg}px) {
        font-size: 32px;
    }
`;

export const PageHeadline = ({ children }) => <Title>{children}</Title>;
