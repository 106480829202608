import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Alert, Skeleton, TableCell, TableRow } from '@mui/material';
import match from 'autosuggest-highlight/match';
import React, { useMemo } from 'react';
import { SkeletonButton } from '../../common/components/button/SkeletonButton';
import { getResponseErrorMessage } from '../../core/api/error';
import { NothingHereToSee } from '../../core/components/NothingHereToSee';
import { NfcTag } from '../nfcTag';
import { QUERY_NFC_TAGS } from '../queries';
import { NfcTagsTableRow } from './NfcTagsTableRow';
import { NfcTagsTableWrapper } from './NfcTagsTableWrapper';

const SkeletonText = styled(Skeleton)`
    font-size: 17px;
`;

const EmptyTable = ({ children }) => (
    <NfcTagsTableWrapper totalCount={0}>
        <TableRow>
            <TableCell colSpan={5}>{children}</TableCell>
        </TableRow>
    </NfcTagsTableWrapper>
);

const UserSkeleton = () => (
    <div style={{ display: 'inline-flex', gap: 8 }}>
        <Skeleton variant="circular" sx={{ width: '2em', height: '2em' }} />
        <SkeletonText
            variant="text"
            sx={{ width: `${8 + 4 * Math.random()}em` }}
        />
    </div>
);

const SkeletonTableRow = () => {
    return (
        <TableRow>
            <TableCell>
                <SkeletonText variant="text" sx={{ width: '0.5em' }} />
            </TableCell>
            <TableCell align={'center'}>
                <Skeleton
                    variant="rounded"
                    sx={{ width: `${3 + 2 * Math.random()}em` }}
                />
            </TableCell>
            <TableCell>
                <Skeleton
                    variant="rounded"
                    sx={{ width: `${6 + 3 * Math.random()}em` }}
                />
            </TableCell>
            <TableCell>
                <UserSkeleton />
            </TableCell>
            <TableCell style={{ paddingLeft: 0 }}>
                <SkeletonText variant="text" sx={{ width: '120px' }} />
            </TableCell>
        </TableRow>
    );
};

const SkeletonTable = () => (
    <NfcTagsTableWrapper totalCount={'?'} loading>
        <SkeletonTableRow />
        <SkeletonTableRow />
        <SkeletonTableRow />
        <SkeletonTableRow />
    </NfcTagsTableWrapper>
);

type NfcTagsTableProps = {
    labelQuery?: string;
};

function getFilteredList(
    query: string | undefined,
    data: Partial<NfcTag>[]
): Partial<NfcTag>[] {
    const cleanQuery = (query || '').trim();
    if (!cleanQuery) {
        return data;
    }

    return data.filter(nfcTag => {
        if (!nfcTag) {
            return false;
        }

        return (
            match(
                `${nfcTag.label} ${nfcTag.user?.firstName} ${nfcTag.user?.lastName} ${nfcTag.user?.email}`,
                cleanQuery
            ).length > 0
        );
    });
}

export const NfcTagsTable = ({ labelQuery }: NfcTagsTableProps) => {
    const { loading, error, data, refetch } = useQuery(QUERY_NFC_TAGS, {
        fetchPolicy: 'cache-and-network',
        pollInterval: 10000
    });

    const _nfcTagNodes = data?.me?.organization?.nfcTags?.nodes;
    const totalCount: number = data?.me?.organization?.nfcTags?.totalCount || 0;

    const nfcTags = useMemo(() => {
        return _nfcTagNodes || [];
    }, [_nfcTagNodes]);

    const filteredNfcTags = useMemo(
        () => getFilteredList(labelQuery, nfcTags),
        [nfcTags, labelQuery, getFilteredList]
    );

    function onNfcTagChange() {
        refetch();
    }

    if (!data && loading) {
        return <SkeletonTable />;
    }

    if (error) {
        const errorMessage = getResponseErrorMessage(error);
        return (
            <EmptyTable>
                <Alert severity="error">
                    ID-Karten konnten nicht geladen werden :-( <br />
                    <small>{errorMessage}</small>
                </Alert>
            </EmptyTable>
        );
    }

    if (!nfcTags || nfcTags.length === 0) {
        return (
            <EmptyTable>
                <NothingHereToSee
                    size={'small'}
                    label={
                        'Keine ID-Karten gefunden. Bitte kontaktiere den Support, um ID-Karten zu bestellen.'
                    }
                />
            </EmptyTable>
        );
    }

    const tableData = filteredNfcTags || nfcTags;

    if (labelQuery && tableData.length === 0) {
        return (
            <EmptyTable>
                Keine ID-Karten mit der Beschriftung bzw. mit zugeordnetem
                Mitarbeiter „{labelQuery?.trim()}“ gefunden
            </EmptyTable>
        );
    }

    return (
        <NfcTagsTableWrapper
            loading={loading}
            totalCount={totalCount}
            filteredCount={tableData.length || undefined}>
            {tableData.map((nfcTag, i) => (
                <NfcTagsTableRow
                    key={nfcTag.id}
                    index={i}
                    nfcTag={nfcTag}
                    onNfcTagChange={onNfcTagChange}
                />
            ))}
        </NfcTagsTableWrapper>
    );
};
