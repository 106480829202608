import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import ArticleIcon from '@mui/icons-material/Article';
import ReplayIcon from '@mui/icons-material/Replay';
import { Chip, Link, Tooltip } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { useParams } from 'react-router-dom';
import { PrimaryButton } from '../common/components/button/PrimaryButton';
import {
    GlobalAlert,
    GlobalAlertActions
} from '../common/components/GlobalAlert';
import { getResponseErrorMessage } from '../core/api/error';
import { getRoleLabel } from '../core/auth';
import { User } from '../core/auth/user';
import { PageBody } from '../core/components/page/PageBody';
import { PageHeader } from '../core/components/page/PageHeader';
import { PageHeadline } from '../core/components/page/PageHeadline';
import { PageSubHeadline } from '../core/components/page/PageSubHeadline';
import { useDocumentTitle } from '../core/hooks/useDocumentTitle';
import {
    getEmployeeOnboardingDocumentUrl,
    Paths
} from '../core/navigation/paths';
import { getUserName } from '../user';
import { UserInvitationChip } from '../user/invitation/UserInvitationChip';
import { isInvitationExpired } from '../user/invitation/utils';
import { QUERY_USER_DETAILS } from '../user/queries';
import { RenewUserInvitationButtonWrapper } from '../user/RenewUserInvitationButtonWrapper';
import { UserMetaMenu } from '../user/UserMetaMenu';
import { UserState } from '../user/userState';
import { EmployeeErrorPage } from './EmployeeErrorPage';
import { PageHeadlineContainer } from './EmployeePageHeadlineContainer';
import { EmployeePageTabNavigation } from './EmployeePageTabNavigation';
import { EmployeePageWrapper } from './EmployeePageWrapper';
import { EmployeeSkeletonPage } from './EmployeeSkeletonPage';

const StyledUserMetaMenu = styled(UserMetaMenu)`
    margin-left: 4px !important;
`;

export const EmployeePageContentWrapper = ({
    renderContent,
    renderSkeletonContent,
    subPageTitle
}: {
    renderContent: (user: User, refetchUser: () => Promise<void>) => any;
    renderSkeletonContent?: () => any;
    subPageTitle: string;
}) => {
    let params = useParams();

    const [pageTitle, setPageTitle] = useState(`${subPageTitle} - Mitarbeiter`);

    useDocumentTitle(pageTitle);

    const { data, loading, error, refetch } = useQuery(QUERY_USER_DETAILS, {
        variables: {
            id: params.employeeId
        }
    });

    const _userNode = data?.node;
    const user = useMemo(() => {
        return _userNode;
    }, [_userNode]);

    const employeeName = getUserName(user);
    const isInactive = user?.state === UserState.INACTIVE;
    const isInvited = user?.state === UserState.INVITED;
    const invitationExpired =
        isInvited && isInvitationExpired(user?.pendingInvitation);

    function onMemberUpdate() {
        refetch();
    }

    useEffect(() => {
        if (user) {
            setPageTitle(`${subPageTitle} - ${employeeName} - Mitarbeiter`);
        } else if (loading) {
            setPageTitle(`${subPageTitle} - Mitarbeiter`);
        } else if (error) {
            setPageTitle('Mitarbeiter:in kann nicht geladen werden');
        } else {
            setPageTitle('Mitarbeiter:in nicht gefunden');
        }
    }, [user, loading, error]);

    if (loading) {
        return (
            <EmployeeSkeletonPage
                renderSkeletonContent={renderSkeletonContent}
            />
        );
    }

    if (error) {
        const errorMessage = getResponseErrorMessage(error, apiError => {
            if (apiError.getCode() === 'user_not_found') {
                return 'Mitarbeiter:in konnte nicht gefunden werden. Möglicherweise wurde er oder sie zwischenzeitlich gelöscht.';
            }
        });
        return (
            <EmployeeErrorPage breadcrumbLabel="?">
                <GlobalAlert severity="error">
                    Mitarbeiter:in konnte nicht geladen werden :-( <br />
                    <small>{errorMessage}</small>
                </GlobalAlert>
            </EmployeeErrorPage>
        );
    }

    if (!user) {
        return (
            <EmployeeErrorPage breadcrumbLabel="?">
                <GlobalAlert severity="error">
                    Mitarbeiter:in nicht gefunden
                </GlobalAlert>
            </EmployeeErrorPage>
        );
    }

    return (
        <EmployeePageWrapper
            breadcrumbs={[
                <Link
                    key="1"
                    underline="hover"
                    color="inherit"
                    href={generatePath(Paths.EmployeeBase, {
                        employeeId: user.id
                    })}>
                    {employeeName}
                </Link>,
                <span key="2">{subPageTitle}</span>
            ]}>
            <PageHeader>
                <PageHeadlineContainer>
                    <PageHeadline>{employeeName}</PageHeadline>
                    <StyledUserMetaMenu user={user} onChange={onMemberUpdate} />
                    {isInactive && (
                        <Tooltip title="Das Konto des Mitarbeiters wurde deaktiviert. Dadurch hat der Mitarbeiter keinen Zugriff mehr auf sein Konto. Auch die Zeiterfassung ist nicht mehr möglich. Die Notizfunktion steht Ihnen jedoch weiterhin zur Verfügung.">
                            <Chip
                                label="Deaktiviert"
                                size="small"
                                sx={{ marginLeft: 1, lineHeight: '16px' }}
                            />
                        </Tooltip>
                    )}
                    {isInvited && <UserInvitationChip user={user} onPage />}
                </PageHeadlineContainer>
                <PageSubHeadline>
                    {user.email}
                    {' • '}
                    <Tooltip title={'Berechtigung'}>
                        <span>{getRoleLabel(user.role)}</span>
                    </Tooltip>
                </PageSubHeadline>

                {isInactive && (
                    <GlobalAlert
                        severity="info"
                        sx={{
                            margin: { xs: '16px 0', md: '24px 0' }
                        }}>
                        Das Konto ihres Mitarbeiters wurde deaktiviert. Dadurch
                        hat der Mitarbeiter keinen Zugriff mehr auf das Konto
                        und eine Erfassung der Arbeitszeit ist nicht mehr
                        möglich. Die Notizfunktion steht Ihnen jedoch weiterhin
                        zur Verfügung.
                    </GlobalAlert>
                )}

                {isInvited && (
                    <GlobalAlert
                        severity={'info'}
                        sx={{
                            margin: { xs: '16px 0', md: '24px 0' }
                        }}>
                        {invitationExpired
                            ? 'Die Einladung für das Konto des Mitarbeiters ist abgelaufen. Das Konto ist noch inaktiv.'
                            : 'Das Konto ist noch inaktiv, da der Mitarbeiter die Einladung noch nicht angenommen hat.'}
                        {invitationExpired && (
                            <GlobalAlertActions>
                                <RenewUserInvitationButtonWrapper
                                    user={user}
                                    userInvitationId={
                                        user.pendingInvitation?.id
                                    }
                                    onSuccess={() => refetch()}
                                    renderButton={(working, onClick) => (
                                        <PrimaryButton
                                            size={'small'}
                                            startIcon={<ReplayIcon />}
                                            disabled={working}
                                            onClick={onClick}>
                                            Mitarbeiter erneut einladen
                                        </PrimaryButton>
                                    )}
                                />
                            </GlobalAlertActions>
                        )}

                        {!invitationExpired && (
                            <GlobalAlertActions>
                                <PrimaryButton
                                    component={'a'}
                                    href={getEmployeeOnboardingDocumentUrl(
                                        user.id!
                                    )}
                                    target={'_blank'}
                                    size={'small'}
                                    startIcon={<ArticleIcon />}
                                    onClick={e => e.stopPropagation()}>
                                    Onboarding-Dokument anzeigen
                                </PrimaryButton>
                            </GlobalAlertActions>
                        )}
                    </GlobalAlert>
                )}

                <EmployeePageTabNavigation user={user} />
            </PageHeader>

            <PageBody>{renderContent(user, () => refetch().then())}</PageBody>
        </EmployeePageWrapper>
    );
};
