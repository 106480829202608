export enum Colors {
    Primary = '#5ac8fa',
    Secondary = '#86868A',

    SecondaryLighter = '#9b9b9e',

    PrimaryLight = '#aae1fa',
    SecondaryLight = '#C4C4C6',

    SecondaryLightest = '#dddedf',
    PrimaryLightest = '#edf6fa',

    PrimaryDark = '#4eadd9',
    PrimaryDarker = '#1581b3',

    SecondaryDark = '#737373',
    SecondaryDarker = '#404040',

    Dark = '#09141a',

    Red = '#FF3B30',
    Green = '#34c759',
    Orange = '#FA9F5A',

    RedLight = '#ff8680',
    RedOrangeLight = '#fa675a',
    GreenLight = '#85c796',
    OrangeLight = '#ffcda3',

    RedLightest = '#ffafab',
    GreenLightest = '#f2fff5',
    OrangeLightest = '#fff4e5',

    RedDark = '#D93229',
    GreenDark = '#2aa147',
    GreenDark2 = '#299e47',
    OrangeDark = '#d98a4e',

    OrangeDarkest = '#a5591a',
    RedDarkest = '#99231d',
    GreenDarkest = '#195e2a',

    /* */

    Background = '#f5f5f5',
    BackgroundLight = '#fafafa',
    BackgroundDark = '#D9D9D9',

    Text = '#09141a',
    TextMedium = '#343b3f',
    TextLight = '#666666',
    TextLighter = '#86868a',
    TextLightest = '#a3a3a8',
    TextPlaceholder = '#86868A',
    TextInlineLabel = '#86868A',
    TextSkeleton = '#bababf',
    TextSkeletonLight = '#d2d2d9',

    Link = '#1581b3',
    LinkHover = '#0d6c98',
    LinkActive = '#09567a',

    Shadow = '#00001C',
    RedShadow = 'rgb(89,18,18)',
    GreenShadow = 'rgb(18,89,53)',

    LineColor = '#D6D6D6',
    LineColorLight = '#ebebeb'
}
