export function shakeElement(
    element?: HTMLElement | null,
    duration: number = 300
) {
    if (!element) {
        console.error('Element to shake is not defined');
        return;
    }

    if (typeof element.animate !== 'function') {
        return;
    }

    element.animate(
        [
            { transform: 'translateX(-5px)' },
            { transform: 'translateX(5px)' },
            { transform: 'translateX(-5px)' },
            { transform: 'translateX(5px)' },
            { transform: 'translateX(-5px)' },
            { transform: 'translateX(5px)' },
            { transform: 'translateX(0)' }
        ],
        {
            duration: duration
        }
    );
}
