import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { CircularProgress, Popover, TextField, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef } from 'react';
import { NotAvailable } from '../../common/components/NotAvailable';
import { getApiErrorCode } from '../../core/api/error';
import { shakeElement } from '../../core/theme/utils';
import { executeSafe } from '../../utils';
import { NfcTag } from '../nfcTag';
import { MUTATION_SET_NFC_TAG_LABEL } from '../queries';
import { NfcTagLabel } from './NfcTagLabel';

const NfcTagLabelCt = styled.div`
    display: flex;
    align-items: center;

    .MuiIconButton-root {
        visibility: hidden;
    }

    &:hover,
    &:focus,
    &:active {
        .MuiIconButton-root {
            visibility: visible;
        }
    }
`;

const StyledEditButton = styled(IconButton)`
    margin-left: 8px;

    svg {
        width: 18px;
        height: 18px;
    }
`;

type EditableNfcTagLabelProps = {
    nfcTag: Partial<NfcTag>;
    onChange: () => void;
};

const POPOVER_ID = 'nfc-tag-edit-label-inline-popover';

export const EditableNfcTagLabel = ({
    nfcTag,
    onChange
}: EditableNfcTagLabelProps) => {
    const [anchorEl, setAnchorEl] =
        React.useState<HTMLButtonElement | null>(null);

    const ctRef = useRef<any>();

    const [isDirty, setIsDirty] = React.useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(ctRef.current);
    };

    const shakePopover = () => {
        shakeElement(document.getElementById(POPOVER_ID));
    };

    const closeInlineForm = () => {
        setAnchorEl(null);
    };

    const onSave = () => {
        closeInlineForm();
        executeSafe(onChange);
    };

    const handleClose = (ignoreDirty: boolean) => {
        if (isDirty && !ignoreDirty) {
            shakePopover();
            return;
        }
        closeInlineForm();
    };

    const popoverIsOpen = Boolean(anchorEl);
    const popoverId = popoverIsOpen ? POPOVER_ID : undefined;

    return (
        <NfcTagLabelCt ref={ctRef}>
            {nfcTag.label ? (
                <NfcTagLabel>{nfcTag.label}</NfcTagLabel>
            ) : (
                <NotAvailable />
            )}
            <Tooltip title={'Beschriftung ändern'} placement={'bottom'}>
                <StyledEditButton
                    aria-label="Beschriftung ändern"
                    onClick={handleClick}>
                    <EditIcon />
                </StyledEditButton>
            </Tooltip>
            <Popover
                id={popoverId}
                open={popoverIsOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                onClose={() => handleClose(false)}>
                <EditNfcTagLabelPopoverForm
                    nfcTag={nfcTag}
                    onSave={onSave}
                    onAbort={() => handleClose(true)}
                    onDirty={setIsDirty}
                />
            </Popover>
        </NfcTagLabelCt>
    );
};

const EditNfcTagLabelPopoverCt = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 6px 8px 16px;
`;

const EditNfcTagLabelPopoverForm = ({
    nfcTag,
    onSave,
    onAbort,
    onDirty
}: {
    nfcTag: Partial<NfcTag>;
    onSave: () => void;
    onAbort: () => void;
    onDirty: (dirty: boolean) => void;
}) => {
    const [label, setLabel] = React.useState<string>(nfcTag.label || '');

    const [working, setWorking] = React.useState<boolean>(false);

    const [mutationSetNfcTagLabel] = useMutation(MUTATION_SET_NFC_TAG_LABEL);

    const { enqueueSnackbar } = useSnackbar();

    const handleSave = async () => {
        if (label === nfcTag.label) {
            executeSafe(onSave);
            return;
        }

        if (!label) {
            enqueueSnackbar('Beschriftung darf nicht leer sein', {
                variant: 'warning',
                autoHideDuration: 5000
            });
            return;
        }

        if (label.length > 100) {
            enqueueSnackbar('Beschriftung darf maximal 100 Zeichen lang sein', {
                variant: 'warning',
                autoHideDuration: 5000
            });
            return;
        }

        if (working) {
            return;
        }
        setWorking(true);

        return mutationSetNfcTagLabel({ variables: { id: nfcTag.id, label } })
            .then(() => {
                enqueueSnackbar(`Beschriftung wurde gespeichert`, {
                    variant: 'success',
                    autoHideDuration: 2000
                });
                executeSafe(onSave);
            })
            .catch(error => {
                const apiErrorCode = getApiErrorCode(error);

                if (apiErrorCode === 'nfc_tag_not_found') {
                    enqueueSnackbar(
                        'Diese ID-Karte wurde zwischenzeitlich deaktiviert.',
                        {
                            variant: 'warning',
                            autoHideDuration: 5000
                        }
                    );
                    executeSafe(onSave);
                    return;
                }

                enqueueSnackbar(
                    `Fehler beim Speichern der Beschriftung: ${error.message}`,
                    {
                        variant: 'error',
                        autoHideDuration: 5000
                    }
                );
            })
            .finally(() => {
                setWorking(false);
            });
    };

    function onKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === 'Enter') {
            handleSave();
        }
    }

    useEffect(() => {
        onDirty(label !== nfcTag.label);
    }, [label, nfcTag.label, onDirty]);

    const initialWidth = Math.max(Math.min(label.length * 12, 300), 150);

    return (
        <EditNfcTagLabelPopoverCt>
            <TextField
                id="nfc-tag-label-inline-input"
                hiddenLabel
                placeholder={'Beschriftung eingeben'}
                variant="standard"
                value={label}
                onKeyPress={onKeyPress}
                onChange={event => setLabel(event.target.value)}
                autoFocus
                style={{ width: initialWidth, marginRight: 8 }}
            />
            <IconButton onClick={handleSave} disabled={working}>
                <CheckIcon />
                {working && (
                    <WorkingButtonOverlay>
                        <CircularProgress size={20} />
                    </WorkingButtonOverlay>
                )}
            </IconButton>
            <IconButton onClick={onAbort} disabled={working}>
                <CancelIcon />
            </IconButton>
        </EditNfcTagLabelPopoverCt>
    );
};

const WorkingButtonOverlay = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
`;
