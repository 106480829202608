import styled from '@emotion/styled';
import KeyIcon from '@mui/icons-material/Key';
import { Box, Skeleton, Tooltip } from '@mui/material';
import React from 'react';
import { ContentCard } from '../../common/components/layout/ContentCard';
import { getRoleLabel } from '../../core/auth';
import { User } from '../../core/auth/user';
import { Colors } from '../../core/theme/Colors';
import { getUserName } from '../../user';

const EmployeeProp = styled.div`
    & + & {
        margin-top: 8px;
    }
`;

const EmployeePropLabel = styled.div`
    font-size: 13px;
    font-weight: normal;
    line-height: 17px;
`;

const EmployeePropValue = styled.div`
    margin-top: 2px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    padding-bottom: 8px;
    border-bottom: 1px solid #dddedf;
`;

const EmailLink = styled.a`
    color: ${Colors.Text};
    text-decoration: none;

    &:hover {
        color: ${Colors.TextLight};
    }
`;

type EmployeeShortInfoProps = {
    data: User;
};

export const EmployeeProfileCard = (props: EmployeeShortInfoProps) => (
    <ContentCard>
        <Box>
            <EmployeeProp>
                <EmployeePropLabel>Name</EmployeePropLabel>
                <EmployeePropValue>{getUserName(props.data)}</EmployeePropValue>
            </EmployeeProp>
            <EmployeeProp>
                <EmployeePropLabel>E-Mail</EmployeePropLabel>
                <EmployeePropValue>
                    <Tooltip
                        title={`E-Mail an ${getUserName(props.data)} schreiben`}
                        arrow>
                        <EmailLink href={`mailto:${props.data.email}`}>
                            {props.data.email}
                        </EmailLink>
                    </Tooltip>
                </EmployeePropValue>
            </EmployeeProp>
            <EmployeeProp>
                <EmployeePropLabel>Berechtigung</EmployeePropLabel>
                <EmployeePropValue>
                    {getRoleLabel(props.data?.role)}
                    {props.data?.isOwner ? (
                        <Tooltip title="Eigentümer">
                            <KeyIcon
                                fontSize="small"
                                sx={{
                                    marginLeft: 1,
                                    verticalAlign: 'text-bottom'
                                }}
                            />
                        </Tooltip>
                    ) : null}
                </EmployeePropValue>
            </EmployeeProp>
        </Box>
    </ContentCard>
);

export const EmployeeShortInfoSkeleton = () => (
    <ContentCard>
        <Box>
            <EmployeeProp>
                <EmployeePropLabel>Name</EmployeePropLabel>
                <EmployeePropValue>
                    <Skeleton variant="text" width={115} />
                </EmployeePropValue>
            </EmployeeProp>
            <EmployeeProp>
                <EmployeePropLabel>E-Mail</EmployeePropLabel>
                <EmployeePropValue>
                    <Skeleton variant="text" width={140} />
                </EmployeePropValue>
            </EmployeeProp>
            <EmployeeProp>
                <EmployeePropLabel>Berechtigung</EmployeePropLabel>
                <EmployeePropValue>
                    <Skeleton variant="text" width={100} />
                </EmployeePropValue>
            </EmployeeProp>
        </Box>
    </ContentCard>
);
