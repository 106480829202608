import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { Chip, Tooltip } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { User, UserInvitation } from '../../core/auth/user';
import { Colors } from '../../core/theme/Colors';
import { getReadableDateTime } from '../../core/datetime';
import { getUserName } from '../index';
import { UserState } from '../userState';

function getTooltipTitle(
    user: User | Partial<User>,
    invitation: UserInvitation | null | undefined,
    isExpired: boolean,
    onPage: boolean
) {
    if (isExpired) {
        return `Die Einladung für ${getUserName(user)} (${user.email}) ist ${
            invitation ? `am ${getReadableDateTime(invitation.validUntil)}` : ''
        } abgelaufen. ${
            !onPage
                ? 'Die Einladung kann auf der Mitarbeiterseite erneuert werden.'
                : ''
        }`;
    }

    if (!invitation) {
        return '';
    }

    return `${getUserName(user)} (${user.email}) wurde am ${getReadableDateTime(
        invitation.createdAt
    )} eingeladen. Das Konto ist noch nicht aktiv, da die Einladung noch nicht angenommen wurde.`;
}

export const UserInvitationChip = ({
    user,
    onPage = false
}: {
    user: User | Partial<User>;
    onPage?: boolean;
}) => {
    if (user.state !== UserState.INVITED) {
        return null;
    }

    const invitation = user.pendingInvitation;
    const isExpired = invitation?.validUntil
        ? DateTime.fromISO(invitation.validUntil).diffNow().valueOf() < 0
        : true;

    const tooltipTitle = getTooltipTitle(user, invitation, isExpired, onPage);

    return (
        <Tooltip title={tooltipTitle}>
            <Chip
                size={'small'}
                icon={
                    <PersonAddAltIcon
                        style={{
                            width: 16,
                            height: 16,
                            color: isExpired ? 'white' : undefined
                        }}
                    />
                }
                label={isExpired ? 'Einladung abgelaufen' : 'Eingeladen'}
                style={{
                    backgroundColor: isExpired ? Colors.OrangeDark : undefined,
                    color: isExpired ? 'white' : undefined,
                    marginLeft: 8,
                    lineHeight: '16px'
                }}
            />
        </Tooltip>
    );
};
