import styled from '@emotion/styled';
import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { ContentCard } from '../../common/components/layout/ContentCard';
import { NfcTag } from '../../nfcTag/nfcTag';
import { executeSafe } from '../../utils';
import { EmployeeAddNfcTag } from './EmployeeAddNfcTag';
import { EmployeeNfcTag } from './EmployeeNfcTag';

const EmployeeIdCardListCt = ({ children }: PropsWithChildren<unknown>) => (
    <ContentCard>
        <Box>{children}</Box>
    </ContentCard>
);

const List = styled.ol`
    list-style-type: none;
    padding: 0;
    margin: 0;
`;

const ListElement = styled.li`
    & + li {
        margin-top: 12px;
    }
`;

export const EmployeeNfcTagList = ({
    user,
    onChange
}: {
    user: any;
    onChange: () => void;
}) => {
    if (!user) return null;
    const userNfcTags = user?.nfcTags;

    const numNfcTags = userNfcTags?.length || 0;
    const hasNfcTags = numNfcTags > 0;

    const refresh = () => {
        executeSafe(onChange);
    };

    return (
        <EmployeeIdCardListCt>
            {hasNfcTags && (
                <List>
                    {userNfcTags.map((nfcTag: NfcTag) => (
                        <ListElement key={nfcTag.id}>
                            <EmployeeNfcTag
                                user={user}
                                nfcTag={nfcTag}
                                onUnassign={refresh}
                                hasOtherNfcTags={numNfcTags > 1}
                            />
                        </ListElement>
                    ))}
                </List>
            )}
            <EmployeeAddNfcTag
                user={user}
                onAdded={refresh}
                hasSome={hasNfcTags}
            />
        </EmployeeIdCardListCt>
    );
};
