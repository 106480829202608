import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import SensorsIcon from '@mui/icons-material/Sensors';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { PrimaryButton } from '../../common/components/button/PrimaryButton';
import { SecondaryButton } from '../../common/components/button/SecondaryButton';
import { NotAvailable } from '../../common/components/NotAvailable';
import { getApiErrorCode } from '../../core/api/error';
import { User } from '../../core/auth/user';
import { Colors } from '../../core/theme/Colors';
import { NfcTag } from '../../nfcTag/nfcTag';
import { MUTATION_UNASSIGN_NFC_TAG } from '../../nfcTag/queries';
import { getOfficialDateTimeLabel } from '../../timeTracking/time';
import { getUserName } from '../../user';
import { executeSafe } from '../../utils';

const Ct = styled.article`
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 12px;
    background: ${Colors.Background};
    transition: border-color 0.2s, background 0.2s;

    &:hover {
        border-color: ${Colors.SecondaryLightest};
        background: ${Colors.BackgroundLight};
    }
`;

const HeaderCt = styled.div`
    display: flex;
    gap: 24px;
    width: 100%;
`;

const ActionsCt = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 8px;
    margin-left: 98px;
`;

const CardSymbol = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 75px;
    height: 50px;
    background: white;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid ${Colors.SecondaryLightest};
`;

const StyledNfcIcon = styled(SensorsIcon)`
    position: absolute;
    z-index: 3;
    height: 18px;
    width: 18px;
    top: 2px;
    right: 4px;
    fill: ${Colors.SecondaryLight};
`;

const StyledPersonIcon = styled(PersonIcon)`
    width: 45px;
    height: 45px;
    fill: ${Colors.Background};
`;

const UnassignNfcTagButton = styled(Button)`
    svg {
        fill: ${Colors.Secondary};
        margin-right: -3px;
        margin-top: -1px;
    }

    &:hover,
    &:focus {
        svg {
            fill: ${Colors.Red};
        }
    }
`;

type Props = {
    user: User;
    nfcTag: NfcTag;
    onUnassign: () => void;
    hasOtherNfcTags: boolean;
};

export const EmployeeNfcTag = ({
    user,
    nfcTag,
    onUnassign,
    hasOtherNfcTags
}: Props) => {
    const ctRef = useRef<any>();

    const [isWorking, setWorking] = useState(false);
    const [showConfirmUnassignDialog, setShowConfirmUnassignDialog] =
        useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const [mutationUnassignNfcTag] = useMutation(MUTATION_UNASSIGN_NFC_TAG);

    const onUnassignClick = () => {
        if (!nfcTag) {
            return;
        }

        // If a user is inactive, we can safely unassign the NFC tag without confirmation
        if (user.state === 'INACTIVE' || hasOtherNfcTags) {
            unassignNfcTag();
        } else {
            setShowConfirmUnassignDialog(true);
        }
    };

    const onConfirmUnassignNfcTagClick = () => {
        unassignNfcTag();
    };

    const unassignNfcTag = () => {
        if (isWorking || !nfcTag) {
            return;
        }
        setWorking(true);

        mutationUnassignNfcTag({ variables: { id: nfcTag.id } })
            .then(_ => {
                enqueueSnackbar(
                    `Zuweisung von ${getUserName(user)} zu ID-Karte „${
                        nfcTag.label
                    }“ wurde aufgehoben`,
                    {
                        variant: 'success',
                        autoHideDuration: 5000
                    }
                );
                executeSafe(onUnassign);
            })
            .catch(error => {
                const apiErrorCode = getApiErrorCode(error);

                if (apiErrorCode === 'nfc_tag_not_assigned') {
                    enqueueSnackbar(
                        `Zuweisung von ${getUserName(user)} zu ID-Karte „${
                            nfcTag.label
                        }“ ist bereits aufgehoben`,
                        {
                            variant: 'success',
                            autoHideDuration: 5000
                        }
                    );
                    executeSafe(onUnassign);
                    return;
                }

                if (apiErrorCode === 'nfc_tag_not_found') {
                    enqueueSnackbar(
                        'Diese ID-Karte wurde zwischenzeitlich deaktiviert.',
                        {
                            variant: 'warning',
                            autoHideDuration: 5000
                        }
                    );
                    executeSafe(onUnassign);
                    return;
                }

                enqueueSnackbar(
                    `Fehler beim Löschen der Kartenzuweisung: ${error.message}`,
                    {
                        variant: 'error',
                        autoHideDuration: 5000
                    }
                );
            })
            .finally(() => {
                setWorking(false);
                setShowConfirmUnassignDialog(false);
            });
    };

    return (
        <Ct ref={ctRef}>
            <HeaderCt>
                <CardSymbol>
                    <StyledNfcIcon />
                    <StyledPersonIcon />
                </CardSymbol>

                <div
                    style={{
                        flex: 1,
                        marginTop: 2
                    }}>
                    <Tooltip
                        title={
                            nfcTag.label
                                ? 'Beschriftung auf ID-Karte'
                                : 'Es wurde bisher keine Beschriftung für diese ID-Karte angegeben oder die ID-Karte hat keine Beschriftung'
                        }
                        placement={'left'}
                        arrow>
                        <h3 style={{ margin: 0 }}>
                            {nfcTag.label ? (
                                nfcTag.label
                            ) : (
                                <NotAvailable
                                    label={'– Ohne Beschriftung –'}
                                    showTooltip={false}
                                />
                            )}
                        </h3>
                    </Tooltip>
                    <div
                        style={{
                            fontSize: 14,
                            lineHeight: '20px',
                            color: Colors.SecondaryDark
                        }}>
                        Zugewiesen am{' '}
                        {getOfficialDateTimeLabel(nfcTag.assignedAt)}
                    </div>
                </div>
            </HeaderCt>

            <ActionsCt>
                <UnassignNfcTagButton
                    onClick={onUnassignClick}
                    variant={'text'}
                    color={'secondary'}
                    size={'small'}
                    startIcon={
                        <PersonOffIcon
                            style={{
                                // fill: Colors.RedDark,
                                transform: 'scale(-1, 1)',
                                transformOrigin: 'center'
                            }}
                        />
                    }>
                    Zuweisung aufheben
                </UnassignNfcTagButton>
            </ActionsCt>

            {user.state !== 'INACTIVE' && (
                <Dialog
                    open={showConfirmUnassignDialog}
                    keepMounted={false}
                    onClose={() => setShowConfirmUnassignDialog(false)}
                    aria-labelledby="nfc-unassign-confirmation-title"
                    aria-describedby="nfc-unassign-confirmation-description">
                    <DialogTitle id="nfc-unassign-confirmation-title">
                        Zuweisung aufheben
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="nfc-unassign-confirmation-description">
                            Möchten Sie die Zuweisung von ID-Karte „
                            <strong>{nfcTag.label}</strong>“ zu Mitarbeiter:in{' '}
                            <strong>{getUserName(user)}</strong> wirklich
                            aufheben?
                        </DialogContentText>
                        <Alert severity={'warning'} style={{ marginTop: 16 }}>
                            Hierdurch verliert {getUserName(user)} den Zugriff
                            auf die Dajeh-Terminals.
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton
                            onClick={() => setShowConfirmUnassignDialog(false)}
                            autoFocus>
                            Abbrechen
                        </SecondaryButton>
                        <PrimaryButton
                            onClick={() => onConfirmUnassignNfcTagClick()}>
                            Zuweisung aufheben
                        </PrimaryButton>
                    </DialogActions>
                </Dialog>
            )}
        </Ct>
    );
};
