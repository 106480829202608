import styled from '@emotion/styled';
import {
    BarChart,
    ExpandLess,
    ExpandMore,
    WatchLater
} from '@mui/icons-material';
import BadgeIcon from '@mui/icons-material/Badge';
import HelpIcon from '@mui/icons-material/Help';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Container } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import logoImg from '../../assets/images/dajeh-bildmarke_24.png';
import logoImg2x from '../../assets/images/dajeh-bildmarke_48.png';
import { EmployeeIcon } from '../../employee/components/EmployeeIcon';
import { MainNavigationNotificationsButton } from '../../notification/MainNavigationNotificationsButton';
import { OnOrgFeature } from '../../organization/components/OnOrgFeature';
import { getUserName } from '../../user';
import { UserAvatar } from '../../user/UserAvatar';
import { isAdmin } from '../auth';
import { BetaBadge } from '../components/TeaserBetaBadge';
import { useAuthenticatedContext } from '../hooks/useAuthenticatedContext';
import { useWindowInnerWidth } from '../hooks/useWindowInnerWidth';
import { Breakpoints, NAV_BREAK_DESKTOP } from '../theme/Breakpoints';
import { Colors } from '../theme/Colors';
import { AppDrawerNavigation } from './AppDrawerNavigation';
import { MainNavigationLink } from './MainNavigationLink';
import { PathPermissions, Paths } from './paths';
import { MainNavigationTrialPeriodIndicator } from './SubscriptionIndicator';

const StyledAppBar = styled(AppBar)`
    height: 56px !important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 1100;
    left: 0;
    right: 0;
`;

const StyledToolbar = styled(Toolbar)`
    height: 56px !important;
    min-height: 56px !important;
`;

const HomeLink = styled(NavLink)`
    display: flex;
    padding: 0 15px;
    margin: 0 36px 0 -15px;
    align-items: center;
    text-decoration: none !important;
    max-width: 100%;
    overflow: hidden;
`;

const Logo = styled.img`
    display: block;
    height: 24px;
    width: 24px;
`;

const OrganizationNameCt = styled.span`
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 12px;
    padding: 10px 0 10px 13px;
    border-left: 1px solid ${Colors.LineColor};
    overflow: hidden;
`;

const OrganizationName = styled.span`
    color: ${Colors.Secondary};
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (min-width: ${NAV_BREAK_DESKTOP}px) {
        max-width: 300px;
    }
`;

const UserMenuCt = styled(Box)`
    display: none;
    border-left: 1px solid #dddedf;
    height: 40px;
    margin-right: -16px;
    padding-left: 16px;
    flex-grow: 0;

    @media (min-width: ${NAV_BREAK_DESKTOP}px) {
        display: flex;
    }
`;

const UserMenuButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    padding-left: 8px;
    padding-right: 9px;
    color: ${Colors.Text};

    &:hover {
        background-color: ${Colors.Background};
        color: black;

        svg {
            fill: black;
        }
    }
`;

const UserMenuItemBetaBadge = styled(BetaBadge)`
    margin-left: 6px;
`;

const UserMenuLabel = styled.span`
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    padding-left: 8px;
    white-space: nowrap;
`;

const UserMenuToggleIconCt = styled.span`
    height: 22px;
    margin-left: 4px;

    svg {
        fill: #86868a;
        margin-top: -3px;
    }
`;

const UserMenu = styled(Menu)`
    .MuiPaper-root {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
        border-radius: 11px;
        border: 1px solid #c4c4c6;
    }

    .MuiList-root {
        padding: 0 0 6px;
    }

    .MuiMenuItem-root {
        min-width: 235px;
        font-size: 16px;
        line-height: 20px;
    }
`;

const MobileMenuButton = styled(IconButton)`
    margin-left: -8px;
`;

const UserSubMenuCt = styled.li`
    border-bottom: 1px solid #c6c6c8;
    padding: 0 0 6px;
    margin: 0 0 6px;
`;

const UserSubMenu = styled.ul`
    padding: 0;
    margin: 0;

    svg {
        fill: ${Colors.SecondaryLight};
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }

    li:hover {
        svg {
            fill: ${Colors.Secondary};
        }
    }
`;

const SignOutMenuItem = styled(MenuItem)`
    color: rgb(90, 200, 250);
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 0;
`;

const MenuAreaHeadline = styled.h3`
    color: rgb(134, 134, 138);
    line-height: 17px;
    font-size: 13px;
    font-weight: 600;
    margin: 12px 0 0;
    padding: 0 16px;
`;

const DesktopNavLinksCt = styled.div`
    display: none;

    @media (min-width: ${NAV_BREAK_DESKTOP}px) {
        display: flex;
        flex-grow: 1;
    }
`;

const DesktopMainLinksCt = styled.div`
    display: flex;
    flex-grow: 1;
`;

const MobileSpacerElement = styled.div`
    display: flex;
    flex-grow: 1;
    @media (min-width: ${NAV_BREAK_DESKTOP}px) {
        display: none;
    }
`;

const MobileMenuButtonCt = styled.div`
    display: flex;
    flex-grow: 0;
    margin-right: -16px;
    @media (min-width: ${Breakpoints.md}px) {
        margin-right: -12px;
    }

    @media (min-width: ${NAV_BREAK_DESKTOP}px) {
        display: none;
    }
`;

const TimeTrackingIconCt = styled.span`
    position: relative;
    z-index: 1;
    float: left;
`;

const ActiveTimeTrackingDot = styled.span`
    position: absolute;
    z-index: 2;
    top: -2px;
    right: -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${Colors.Red};
    border: 1px solid white;
    opacity: 1;
    transition: opacity 0.2s;

    .active & {
        opacity: 0;
    }
`;

export const AppNavigationBar = () => {
    const [anchorElUser, setAnchorElUser] =
        React.useState<null | HTMLElement>(null);
    const [showDrawerNavigation, setShowDrawerNavigation] = useState(false);
    const authContext = useAuthenticatedContext();

    let navigate = useNavigate();

    const { user } = authContext;
    const userName = getUserName(user);
    const orgName = user?.organization?.name;
    const userMenuIsOpen = Boolean(anchorElUser);
    const userIsAdmin = isAdmin(user);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const userMenuTo = (path: string) => {
        navigate(path);
        handleCloseUserMenu();
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const signOut = () => {
        authContext.signOut();
    };

    const handleOpenMobileNav = () => {
        setShowDrawerNavigation(!showDrawerNavigation);
    };

    const windowInnerWidth = useWindowInnerWidth();
    const isMobile = windowInnerWidth <= NAV_BREAK_DESKTOP;
    const userHasActiveTimeTracking = !!user?.activeTimeTracking;

    return (
        <>
            <StyledAppBar position="fixed" elevation={0}>
                <Container>
                    <StyledToolbar disableGutters>
                        <HomeLink to={Paths.Home}>
                            <Logo
                                src={logoImg}
                                width={24}
                                height={24}
                                srcSet={`${logoImg} 1x, ${logoImg2x} 2x`}
                                alt="Dajeh - So geht Zeiterfassung"
                            />
                            {orgName && (
                                <OrganizationNameCt>
                                    <OrganizationName>
                                        {orgName}
                                    </OrganizationName>
                                </OrganizationNameCt>
                            )}
                        </HomeLink>

                        {/*

                            Desktop

                        */}

                        <DesktopNavLinksCt>
                            <DesktopMainLinksCt>
                                <MainNavigationLink
                                    label="Zeiterfassung"
                                    to={Paths.WorkTimeTracking}
                                    icon={
                                        <TimeTrackingIconCt>
                                            <WatchLater
                                                style={{ fontSize: 20 }}
                                                aria-hidden
                                            />
                                            {userHasActiveTimeTracking && (
                                                <ActiveTimeTrackingDot />
                                            )}
                                        </TimeTrackingIconCt>
                                    }
                                />

                                <MainNavigationLink
                                    label="Bericht"
                                    to={Paths.OrganizationWorkTimeReportWOP}
                                    permissions={
                                        PathPermissions.OrganizationWorkTimeReport
                                    }
                                    icon={
                                        <BarChart
                                            aria-hidden
                                            style={{
                                                fontSize: 27.43,
                                                margin: '-0.15em'
                                            }}
                                        />
                                    }
                                />

                                <MainNavigationLink
                                    label="Mitarbeiter"
                                    to={Paths.EmployeeList}
                                    permissions={PathPermissions.Employees}
                                    icon={
                                        <EmployeeIcon
                                            aria-hidden
                                            style={{
                                                fontSize: 27.43,
                                                margin: '-0.15em 0'
                                            }}
                                        />
                                    }
                                />
                            </DesktopMainLinksCt>

                            {!isMobile && (
                                <MainNavigationTrialPeriodIndicator />
                            )}

                            <MainNavigationNotificationsButton />

                            <MainNavigationLink
                                label="Hilfe"
                                to={'/help'}
                                icon={
                                    <HelpIcon
                                        aria-hidden
                                        style={{
                                            fontSize: 24,
                                            margin: '-2px 0 0 -2px'
                                        }}
                                    />
                                }
                            />
                        </DesktopNavLinksCt>

                        <UserMenuCt>
                            <UserMenuButton
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleOpenUserMenu}
                                variant="text"
                                color="secondary">
                                <div
                                    style={{
                                        width: 32,
                                        height: 32,
                                        marginLeft: -4,
                                        marginTop: -4,
                                        marginBottom: -4
                                    }}>
                                    <UserAvatar user={user} greyscale />
                                </div>
                                <UserMenuLabel>{userName}</UserMenuLabel>
                                <UserMenuToggleIconCt>
                                    {userMenuIsOpen ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </UserMenuToggleIconCt>
                            </UserMenuButton>
                            <UserMenu
                                open={userMenuIsOpen}
                                onClose={handleCloseUserMenu}
                                keepMounted
                                anchorEl={anchorElUser}
                                sx={{ mt: '45px' }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}>
                                <UserSubMenuCt>
                                    <MenuAreaHeadline>
                                        {userName}
                                    </MenuAreaHeadline>
                                    <UserSubMenu>
                                        <MenuItem
                                            onClick={() =>
                                                userMenuTo(
                                                    Paths.UserNotifications
                                                )
                                            }>
                                            <NotificationsIcon aria-hidden />{' '}
                                            Benachrichtigungen
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                userMenuTo(Paths.UserSettings)
                                            }>
                                            <SettingsIcon /> Einstellungen
                                        </MenuItem>
                                    </UserSubMenu>
                                </UserSubMenuCt>
                                {userIsAdmin && (
                                    <UserSubMenuCt>
                                        <MenuAreaHeadline>
                                            {orgName}
                                        </MenuAreaHeadline>
                                        <UserSubMenu>
                                            <MenuItem
                                                onClick={() =>
                                                    userMenuTo(
                                                        Paths.EmployeeList
                                                    )
                                                }>
                                                <EmployeeIcon aria-hidden />{' '}
                                                Mitarbeiter
                                            </MenuItem>
                                            <OnOrgFeature feature={'id_tags'}>
                                                <MenuItem
                                                    onClick={() =>
                                                        userMenuTo(Paths.IdTags)
                                                    }>
                                                    <BadgeIcon aria-hidden />{' '}
                                                    ID-Karten{' '}
                                                    <UserMenuItemBetaBadge
                                                        size={'small'}
                                                    />
                                                </MenuItem>
                                            </OnOrgFeature>
                                        </UserSubMenu>
                                    </UserSubMenuCt>
                                )}
                                <SignOutMenuItem onClick={signOut}>
                                    Abmelden
                                </SignOutMenuItem>
                            </UserMenu>
                        </UserMenuCt>

                        {/*

                            Mobile

                        */}
                        <MobileSpacerElement />

                        {isMobile && (
                            <div style={{ marginRight: '16px' }}>
                                <MainNavigationNotificationsButton />
                                <MainNavigationTrialPeriodIndicator />
                            </div>
                        )}

                        <MobileMenuButtonCt>
                            <MobileMenuButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleOpenMobileNav}>
                                <MenuIcon />
                            </MobileMenuButton>
                        </MobileMenuButtonCt>
                    </StyledToolbar>
                </Container>
            </StyledAppBar>
            <Box sx={{ height: 56 }} />
            <AppDrawerNavigation
                open={showDrawerNavigation}
                onClose={() => setShowDrawerNavigation(false)}
            />
        </>
    );
};
