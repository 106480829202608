import React from 'react';
import { ContactSupportLink } from '../../../common/components/ContactSupportLink';
import { ContentCard } from '../../../common/components/layout/ContentCard';
import { PageBody } from '../../components/page/PageBody';
import { PageHeadline } from '../../components/page/PageHeadline';
import { PageSubHeadline } from '../../components/page/PageSubHeadline';
import { PublicPageHeader } from '../../components/page/PublicPageHeader';
import { ProtectedPage } from './ProtectedPage';

// TODO: Add upselling

export const InactiveFeatureErrorPage = ({ title }: { title?: string }) => (
    <ProtectedPage title={title}>
        <PageBody>
            <PublicPageHeader
                style={{ textAlign: 'center', marginTop: '10vh' }}>
                <PageHeadline>
                    Bereich nicht freigeschaltet
                    <PageSubHeadline>({title})</PageSubHeadline>
                </PageHeadline>
            </PublicPageHeader>

            <ContentCard
                style={{
                    maxWidth: 600,
                    margin: '0 auto',
                    textAlign: 'center'
                }}>
                <p>Dieser Bereich wurde noch nicht freigeschaltet.</p>
                <p>
                    Wenn du denkst, dass das ein Fehler ist, melde dich bitte
                    beim Dajeh Support. Wir helfen dir schnell und
                    unkompliziert.
                </p>
                <p>
                    <ContactSupportLink />
                </p>
            </ContentCard>
        </PageBody>
    </ProtectedPage>
);
