import { deburr } from 'lodash';
import { DateTime } from 'luxon';
import { hslToHex } from '../common/color';
import { User } from '../core/auth/user';
import { Colors } from '../core/theme/Colors';

export const getUserName = (user: User | Partial<User> | null | undefined) => {
    if (!user) {
        return 'n.a.';
    }

    if (user.name) {
        return user.name;
    }

    return `${user.firstName} ${user.lastName}`;
};

export const userHasSomePermissions = (
    user: User | null | undefined,
    permissionsNeeded?: string | string[]
) => {
    if (!permissionsNeeded || permissionsNeeded.length === 0) {
        return true;
    }

    const userPermissions = user?.permissions;

    if (!userPermissions || userPermissions.length === 0) {
        return false;
    }

    const requiredPermissions =
        typeof permissionsNeeded === 'string'
            ? [permissionsNeeded]
            : permissionsNeeded;

    return requiredPermissions.some(permission =>
        userPermissions.includes(permission)
    );
};

export function getGreeting(firstName?: string | null): string {
    const now = DateTime.now();
    const hour = now.hour;

    const genericGreeting = (firstName?: string | null) => {
        return `Hallo${firstName ? ` ${firstName}` : ''}!`;
    };

    if (hour <= 4 || hour > 20) {
        return genericGreeting(firstName);
    } else if (hour <= 10) {
        return `Guten Morgen${firstName ? `, ${firstName}` : ''}!`;
    } else if (hour <= 17) {
        return `Guten Tag${firstName ? `, ${firstName}` : ''}!`;
    } else if (hour <= 20) {
        return `Guten Abend${firstName ? `, ${firstName}` : ''}!`;
    } else {
        return genericGreeting(firstName);
    }
}

export function isStrassenUser(user: User | null | undefined) {
    if (!user) {
        return false;
    }

    if (!user.email) {
        return false;
    }

    return user.email.indexOf('@transporte-strassen.de') > 0;
}

export function getUserAvatarBgColor(
    user?: Partial<User>,
    bright?: boolean
): string {
    const DEFAULT_BG_COLOR = Colors.BackgroundDark;
    if (!user) {
        return DEFAULT_BG_COLOR;
    }

    if (user.state === 'INACTIVE') {
        return DEFAULT_BG_COLOR;
    }

    const initials = getUserInitials(user);
    if (!initials || initials.length === 0 || initials === '?') {
        return DEFAULT_BG_COLOR;
    }

    let base = initials.slice(0, 2).toLowerCase();
    if (base.length === 1) {
        base = `${base}${base}`;
    }

    const asciiInitials = deburr(base);

    function getFirstLetterIndex(s: string) {
        return Math.max(Math.min(122, s.charCodeAt(0)), 97) - 97 || 0;
    }

    const letterIndex1 = getFirstLetterIndex(asciiInitials.charAt(0));
    const letterIndex2 = getFirstLetterIndex(asciiInitials.charAt(1));

    // We calculate the hue based on the first and the second letter of the initials
    const hue = (letterIndex1 + letterIndex2 / 25) / 26;
    return hslToHex(hue, bright ? 0.1 : 1, 0.9);
}

export function getUserInitials(user: Partial<User>): string {
    let initials = '';

    if (user.firstName) {
        initials += user.firstName[0];
    }

    if (user.lastName) {
        initials += user.lastName[0];
    }

    if (!initials && user.email) {
        initials = user.email[0];
    }

    if (!initials) {
        return '?';
    }

    return initials;
}
