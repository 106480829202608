import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import {
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    NativeSelect
} from '@mui/material';
import React, { useState } from 'react';
import { ListFilterBar } from '../../core/components/list/ListFilterBar';
import { ListWithFilters } from '../../core/components/list/ListWithFilters';
import { Colors } from '../../core/theme/Colors';
import { NfcTagsTable } from './NfcTagsTable';

const StyledInput = styled(Input)`
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 17px !important;

    &:before {
        border-bottom-color: ${Colors.SecondaryLight};
    }

    input {
        font-size: 20px;
        line-height: 25px;
        height: 25px;
        font-weight: bold;
        padding-top: 0;
        padding-bottom: 0;
        color: ${Colors.TextLight};
        min-width: 350px;

        &::placeholder {
            color: ${Colors.SecondaryLight};
            opacity: 1;
        }
    }
`;

const StyledNativeSelect = styled(NativeSelect)`
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 17px !important;
    margin-right: 24px;

    &:before {
        border-bottom-color: ${Colors.SecondaryLight};
    }

    select {
        font-size: 20px;
        line-height: 25px;
        height: 25px;
        font-weight: bold;
        padding-top: 0;
        padding-bottom: 0;
        color: ${Colors.TextLight};

        &::placeholder {
            color: ${Colors.SecondaryLight};
            opacity: 1;
        }

        &.selected--active {
            color: ${Colors.GreenDark};
        }

        option {
            font-weight: normal;
            color: unset;
        }
    }

    svg {
        top: calc(50% - 0.5em);
    }
`;

export const NfcTagList = () => {
    const [labelQuery, setLabelQuery] = useState<string | undefined>('');

    return (
        <ListWithFilters>
            <ListFilterBar>
                <FormControl>
                    <InputLabel
                        shrink
                        disableAnimation
                        htmlFor="search-nfc-tag-input">
                        Suche
                    </InputLabel>
                    <StyledInput
                        id="search-nfc-tag-input"
                        value={labelQuery}
                        onChange={event => setLabelQuery(event.target.value)}
                        placeholder="Beschriftung oder Mitarbeiter suchen"
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchIcon
                                    sx={{
                                        fontSize: 22,
                                        color: Colors.SecondaryLight
                                    }}
                                />
                            </InputAdornment>
                        }
                        autoFocus
                    />
                </FormControl>
            </ListFilterBar>

            <NfcTagsTable labelQuery={labelQuery} />
        </ListWithFilters>
    );
};
