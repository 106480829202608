import { PropsWithChildren } from 'react';
import { useUser } from '../../core/auth/useUser';
import { orgHasFeature } from '../utils';

type OnOrgFeature = {
    feature: string;
};

export const OnOrgFeature = ({
    feature,
    children
}: PropsWithChildren<OnOrgFeature>) => {
    const user = useUser();
    const hasFeature = orgHasFeature(user?.organization, feature);
    return hasFeature ? <>{children}</> : null;
};
