import styled from '@emotion/styled';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyIcon from '@mui/icons-material/Key';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from '../../common/components/button/SecondaryButton';
import { getRoleLabel, isAdmin } from '../../core/auth';
import { User } from '../../core/auth/user';
import { Colors } from '../../core/theme/Colors';
import { NfcTagLabel } from '../../nfcTag/components/NfcTagLabel';
import { UserInvitationChip } from '../../user/invitation/UserInvitationChip';
import { UserMetaMenu } from '../../user/UserMetaMenu';
import { UserState } from '../../user/userState';
import { OnOrgFeature } from './OnOrgFeature';

const StyledTableRow = styled(TableRow)`
    td {
        cursor: pointer;
    }

    &.is-inactive,
    &.is-invited {
        td {
            color: ${Colors.TextLight};
        }
    }

    &:hover {
        td {
            background: ${Colors.Background};
        }
    }
`;

const ShowEmployeeDetailsButton = styled(SecondaryButton)`
    .MuiButton-endIcon {
        margin-left: 0;
    }
`;

const NfcTagTableCell = styled(TableCell)`
    white-space: nowrap;
    * {
        margin-right: 0.75em;
    }
`;

type OrganizationMembersTableRowProps = {
    member: Partial<User>;
    onUserChange: () => void;
};

export const OrganizationMembersTableRow = ({
    member,
    onUserChange
}: OrganizationMembersTableRowProps) => {
    const navigate = useNavigate();
    const isOwner: boolean = member.isOwner || false;
    const isInvited = member.state === 'INVITED';
    const isInactive = member.state === UserState.INACTIVE;

    const rowClassName = isInvited
        ? 'is-invited'
        : isInactive
        ? 'is-inactive'
        : '';

    return (
        <>
            <StyledTableRow
                key={member.id}
                onClick={() => navigate(`${member.id}`)}
                className={rowClassName}>
                <TableCell
                    style={{ paddingLeft: 16, paddingRight: 0, width: 60 }}>
                    <UserMetaMenu user={member} onChange={onUserChange} />
                </TableCell>
                <TableCell style={{ paddingLeft: 0 }}>
                    {member.name} <UserInvitationChip user={member} />
                </TableCell>
                <TableCell
                    sx={{ fontWeight: isAdmin(member) ? 600 : undefined }}>
                    {getRoleLabel(member.role)}
                    {isOwner ? (
                        <Tooltip title="Eigentümer">
                            <KeyIcon
                                fontSize="small"
                                sx={{
                                    marginLeft: 1,
                                    verticalAlign: 'text-bottom'
                                }}
                            />
                        </Tooltip>
                    ) : null}
                </TableCell>
                {/*<OnOrgFeature feature={'id_tags'}>*/}
                {/*    <NfcTagTableCell>*/}
                {/*        {member.nfcTags*/}
                {/*            ? member.nfcTags.map(nfcTag => (*/}
                {/*                  <NfcTagLabel key={nfcTag.id}>*/}
                {/*                      {nfcTag.label}*/}
                {/*                  </NfcTagLabel>*/}
                {/*              ))*/}
                {/*            : null}*/}
                {/*    </NfcTagTableCell>*/}
                {/*</OnOrgFeature>*/}
                <TableCell>
                    <ShowEmployeeDetailsButton
                        endIcon={
                            <ChevronRightIcon
                                sx={{ fontSize: '20px !important' }}
                            />
                        }
                        onClick={() => navigate(`${member.id}`)}
                        variant="contained"
                        size="small">
                        Details
                    </ShowEmployeeDetailsButton>
                </TableCell>
            </StyledTableRow>
        </>
    );
};
