import { gql } from '@apollo/client';

export const QUERY_NFC_TAGS = gql`
    query NfcTags {
        me {
            id
            organization {
                id
                nfcTags(last: 100) {
                    totalCount
                    nodes {
                        id
                        label
                        user {
                            id
                            firstName
                            lastName
                            email
                            state
                        }
                        assignedAt
                        updatedAt
                    }
                }
            }
        }
    }
`;

export const MUTATION_SET_NFC_TAG_LABEL = gql`
    mutation SetNfcTagLabel($id: ID!, $label: String!) {
        setNfcTagLabel(id: $id, label: $label) {
            nfcTag {
                id
                label
                updatedAt
            }
        }
    }
`;

export const MUTATION_ASSIGN_NFC_TAG = gql`
    mutation AssignNfcTag($id: ID!, $userId: ID!) {
        assignNfcTag(id: $id, userId: $userId) {
            nfcTag {
                id
                label
                user {
                    id
                    firstName
                    lastName
                    email
                    state
                }
                assignedAt
                updatedAt
            }
        }
    }
`;

export const MUTATION_UNASSIGN_NFC_TAG = gql`
    mutation AssignNfcTag($id: ID!) {
        unassignNfcTag(id: $id) {
            nfcTag {
                id
                label
                user {
                    id
                }
                assignedAt
                updatedAt
            }
        }
    }
`;

export const QUERY_USERS_FOR_NFC_TAG_ASSIGNMENT = gql`
    query UsersForNfcTagAssignment {
        me {
            id
            organization {
                id
                members(states: [ACTIVE, INVITED]) {
                    nodes {
                        id
                        name
                        email
                        state
                        pendingInvitation {
                            id
                        }
                        nfcTags {
                            id
                            label
                            assignedAt
                            updatedAt
                        }
                    }
                }
            }
        }
    }
`;

export const QUERY_NFC_TAGS_FOR_USER_ASSIGNMENT = gql`
    query NfcTagsForUserAssignment {
        me {
            id
            organization {
                id
                nfcTags(last: 100) {
                    totalCount
                    nodes {
                        id
                        label
                        user {
                            id
                            name
                            email
                            state
                        }
                        assignedAt
                        updatedAt
                    }
                }
            }
        }
    }
`;
