import { gql } from '@apollo/client';

export const GET_TIME_ENTRY_DETAILS = gql`
    query GetTimeEntryDetails($timeEntryId: ID!) {
        node(id: $timeEntryId) {
            id
            ... on TimeEntry {
                startDate
                endDate
                description
                totalSeconds
                createdAt
                createdBy {
                    id
                    name
                }
                updatedAt
                updatedBy {
                    id
                    name
                }
                owner {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_MY_ACTIVE_TIME_TRACKING = gql`
    query GetActiveTimeTracking {
        me {
            id
            activeTimeTracking {
                id
                description
                startDate
            }
        }
    }
`;

export const CREATE_TIME_ENTRY_MUTATION = gql`
    mutation createTimeEntry($input: CreateTimeEntryInput!) {
        createTimeEntry(input: $input) {
            timeEntry {
                id
                startDate
                endDate
                description
                totalSeconds
            }
        }
    }
`;

export const UPDATE_TIME_ENTRY_MUTATION = gql`
    mutation updateTimeEntry($id: ID!, $input: UpdateTimeEntryInput!) {
        updateTimeEntry(id: $id, input: $input) {
            timeEntry {
                id
                startDate
                endDate
                description
                totalSeconds
            }
        }
    }
`;

export const DELETE_TIME_ENTRY_MUTATION = gql`
    mutation deleteTimeEntry($id: ID!) {
        deleteTimeEntry(id: $id) {
            timeEntry {
                id
                startDate
                endDate
                description
                totalSeconds
                deletedAt
            }
        }
    }
`;

export const RECOVER_TIME_ENTRY_MUTATION = gql`
    mutation recoverTimeEntry($id: ID!) {
        recoverTimeEntry(id: $id) {
            timeEntry {
                id
                startDate
                endDate
                description
                totalSeconds
                deletedAt
            }
        }
    }
`;

export const QUERY_MY_RECENT_TIME_ENTRIES = gql`
    query MyRecentTimeEntries($last: Int, $after: String) {
        me {
            id
            timeEntries(last: $last, after: $after) {
                edges {
                    node {
                        id
                        startDate
                        endDate
                        totalSeconds
                        description
                        createdAt
                        createdBy {
                            id
                            name
                        }
                        updatedAt
                        updatedBy {
                            id
                            name
                        }
                        owner {
                            id
                            name
                        }
                    }
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`;

export const QUERY_USER_RECENT_TIME_ENTRIES = gql`
    query RecentTimeEntries($userId: ID!, $last: Int, $after: String) {
        node(id: $userId) {
            ... on User {
                id
                timeEntries(last: $last, after: $after) {
                    edges {
                        node {
                            id
                            startDate
                            endDate
                            totalSeconds
                            description
                            createdAt
                            createdBy {
                                id
                                name
                            }
                            updatedAt
                            updatedBy {
                                id
                                name
                            }
                            owner {
                                id
                                name
                            }
                        }
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                    }
                }
            }
        }
    }
`;

export const GET_TIME_ENTRY_AUDIT_LOG_ADMIN = gql`
    query TimeEntryAuditLog($timeEntryId: ID!) {
        node(id: $timeEntryId) {
            id
            ... on TimeEntry {
                createdAt
                createdBy {
                    id
                    firstName
                    lastName
                    email
                }
                updatedAt
                updatedBy {
                    id
                    firstName
                    lastName
                    email
                }
                owner {
                    id
                    firstName
                    lastName
                    email
                }
                auditLog {
                    nodes {
                        id
                        createdAt
                        actorName
                        actor {
                            id
                            firstName
                            lastName
                            email
                        }
                        action
                        operationType
                        startDate
                        endDate
                        description
                    }
                }
            }
        }
    }
`;

export const GET_TIME_ENTRY_AUDIT_LOG = gql`
    query TimeEntryAuditLog($timeEntryId: ID!) {
        node(id: $timeEntryId) {
            id
            ... on TimeEntry {
                id
                ... on TimeEntry {
                    createdAt
                    createdBy {
                        id
                        firstName
                        lastName
                    }
                    updatedAt
                    updatedBy {
                        id
                        firstName
                        lastName
                    }
                    owner {
                        id
                        firstName
                        lastName
                    }
                    auditLog {
                        nodes {
                            id
                            createdAt
                            actorName
                            actor {
                                id
                                firstName
                                lastName
                            }
                            action
                            operationType
                            startDate
                            endDate
                            description
                        }
                    }
                }
            }
        }
    }
`;
