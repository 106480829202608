import styled from '@emotion/styled';
import React from 'react';
import { ContentCard } from '../../common/components/layout/ContentCard';
import { ContentSection } from '../../common/components/layout/ContentSection';
import { getRoleLabel } from '../../core/auth';
import { useUser } from '../../core/auth/useUser';
import { SubPageHeadline } from '../../core/components/page/SubPageHeadline';
import { SubPageSectionHeadline } from '../../core/components/page/SubPageSectionHeadline';
import { Colors } from '../../core/theme/Colors';
import { getUserName } from '../index';
import { UpdateUserPassword } from '../password/UpdateUserPassword';
import { UserSettingsPageContentWrapper } from './UserSettingsPageContentWrapper';

const UserProp = styled.div`
    & + & {
        margin-top: 16px;
    }
`;

const UserPropLabel = styled.div`
    font-weight: 600;
    color: ${Colors.Secondary};
`;

const UserPropValue = styled.div`
    margin-top: 8px;
`;

export const UserSettingsPageContent = () => {
    const user = useUser();
    return (
        <UserSettingsPageContentWrapper>
            <SubPageHeadline>Allgemein</SubPageHeadline>

            <ContentSection>
                <SubPageSectionHeadline first>Profil</SubPageSectionHeadline>

                <ContentCard>
                    <UserProp>
                        <UserPropLabel>Name</UserPropLabel>
                        <UserPropValue>{getUserName(user)}</UserPropValue>
                    </UserProp>

                    <UserProp>
                        <UserPropLabel>E-Mail Adresse</UserPropLabel>
                        <UserPropValue>{user?.email}</UserPropValue>
                    </UserProp>

                    <UserProp>
                        <UserPropLabel>Berechtigung</UserPropLabel>
                        <UserPropValue>
                            {getRoleLabel(user?.role)}
                        </UserPropValue>
                    </UserProp>
                </ContentCard>
            </ContentSection>

            <ContentSection>
                <SubPageSectionHeadline>Passwort ändern</SubPageSectionHeadline>
                <UpdateUserPassword />
            </ContentSection>
        </UserSettingsPageContentWrapper>
    );
};
