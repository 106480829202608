import ArticleIcon from '@mui/icons-material/Article';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SendIcon from '@mui/icons-material/Send';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import {
    CircularProgress,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem
} from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { User } from '../core/auth/user';
import { getEmployeeOnboardingDocumentUrl } from '../core/navigation/paths';
import { Colors } from '../core/theme/Colors';
import { ActivateUserButtonWrapper } from './ActivateUserButtonWrapper';
import { DeactivateUserButtonWrapper } from './DeactivateUserButtonWrapper';
import { EditUserButtonWrapper } from './EditUserButtonWrapper';
import { RenewUserInvitationButtonWrapper } from './RenewUserInvitationButtonWrapper';
import { ResendUserInvitationButtonWrapper } from './ResendUserInvitationButtonWrapper';
import { UserState } from './userState';

type UserMetaMenuProps = {
    user: Partial<User>;
    onChange?: () => void;
    className?: string;
};

export const UserMetaMenu = ({
    user,
    onChange,
    className
}: UserMetaMenuProps) => {
    const [anchorEl, setOtherActionsMenuAnchorEl] =
        React.useState<null | HTMLElement>(null);
    const isOtherActionsMenuOpen = Boolean(anchorEl);

    const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        closeMenu();
    };

    const closeMenu = () => {
        setOtherActionsMenuAnchorEl(null);
    };

    const showMoreActionsMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setOtherActionsMenuAnchorEl(e.currentTarget);
    };

    const isInvited = user.state === UserState.INVITED;
    const invitationIsExpired =
        isInvited &&
        user.pendingInvitation &&
        user.pendingInvitation.validUntil &&
        DateTime.fromISO(user.pendingInvitation.validUntil) < DateTime.now();

    return (
        <>
            <IconButton
                aria-label="Weitere Aktionen"
                onClick={showMoreActionsMenu}
                size="small"
                aria-controls={
                    isOtherActionsMenuOpen ? 'basic-menu' : undefined
                }
                aria-haspopup="true"
                aria-expanded={isOtherActionsMenuOpen ? 'true' : undefined}
                className={className}>
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                id={`${user.id}-user-menu`}
                anchorEl={anchorEl}
                open={isOtherActionsMenuOpen}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}>
                {/* Edit employee data */}
                <EditUserButtonWrapper
                    renderButton={showEditForm => (
                        <MenuItem
                            onClick={e => {
                                e.stopPropagation();
                                showEditForm(user.id!, onChange);
                                closeMenu();
                            }}>
                            <ListItemIcon>
                                <EditIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Bearbeiten</ListItemText>
                        </MenuItem>
                    )}
                />

                {/* Show onboarding document */}
                {isInvited && !invitationIsExpired && (
                    <MenuItem
                        onClick={e => {
                            e.stopPropagation();
                        }}
                        component="a"
                        href={getEmployeeOnboardingDocumentUrl(user.id!)}
                        target="_blank">
                        <ListItemIcon>
                            <ArticleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Onboarding-Dokument anzeigen
                        </ListItemText>
                    </MenuItem>
                )}

                {/* Resend invitation button */}
                {isInvited && !invitationIsExpired && (
                    <ResendUserInvitationButtonWrapper
                        user={user}
                        userInvitationId={user.pendingInvitation!.id!}
                        onSuccess={onChange}
                        renderButton={(working, onClick) => (
                            <MenuItem
                                onClick={e => {
                                    e.stopPropagation();
                                    onClick();
                                }}>
                                <ListItemIcon>
                                    {working ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <SendIcon fontSize="small" />
                                    )}
                                </ListItemIcon>
                                <ListItemText>
                                    Einladung erneut senden
                                </ListItemText>
                            </MenuItem>
                        )}
                    />
                )}

                {/* Renew invitation button */}
                {isInvited && invitationIsExpired && (
                    <RenewUserInvitationButtonWrapper
                        user={user}
                        userInvitationId={user.pendingInvitation!.id!}
                        onSuccess={onChange}
                        renderButton={(working, onClick) => (
                            <MenuItem
                                onClick={e => {
                                    e.stopPropagation();
                                    onClick();
                                }}>
                                <ListItemIcon>
                                    {working ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <ContactMailIcon fontSize="small" />
                                    )}
                                </ListItemIcon>
                                <ListItemText>Erneut einladen</ListItemText>
                            </MenuItem>
                        )}
                    />
                )}

                {[UserState.ACTIVE, UserState.INACTIVE].indexOf(user.state!) >=
                    0 && <Divider style={{ borderColor: Colors.LineColor }} />}

                {/* Deactivate button */}
                {user.state === UserState.ACTIVE && (
                    <DeactivateUserButtonWrapper
                        user={user}
                        onSuccess={onChange}
                        renderButton={(user, working, deactivateUser) => (
                            <MenuItem
                                onClick={e => {
                                    e.stopPropagation();
                                    deactivateUser();
                                }}>
                                <ListItemIcon>
                                    {working ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <ToggleOffIcon
                                            fontSize="small"
                                            style={{
                                                color: Colors.TextLighter
                                            }}
                                        />
                                    )}
                                </ListItemIcon>
                                <ListItemText>
                                    <span
                                        style={{
                                            color: Colors.TextLighter,
                                            fontWeight: 'bold',
                                            fontSize: 14
                                        }}>
                                        Konto deaktivieren
                                    </span>
                                </ListItemText>
                            </MenuItem>
                        )}
                    />
                )}

                {/* Activate button */}
                {user.state === UserState.INACTIVE && (
                    <ActivateUserButtonWrapper
                        user={user}
                        onSuccess={onChange}
                        renderButton={(user, working, activateUser) => (
                            <MenuItem
                                onClick={e => {
                                    e.stopPropagation();
                                    activateUser();
                                }}>
                                <ListItemIcon>
                                    {working ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <ToggleOnIcon
                                            fontSize="small"
                                            style={{
                                                color: Colors.TextLighter
                                            }}
                                        />
                                    )}
                                </ListItemIcon>
                                <ListItemText>
                                    <span
                                        style={{
                                            color: Colors.TextLighter,
                                            fontWeight: 'bold',
                                            fontSize: 14
                                        }}>
                                        Konto aktivieren
                                    </span>
                                </ListItemText>
                            </MenuItem>
                        )}
                    />
                )}
            </Menu>
        </>
    );
};
